import apiEndpoints from "./apiEndpoints";
import apiServices from "./apiServices"
//withdrawal list without shop
export const getShippingWithdrawList = async(count,page) =>
{
    try{
        const response = await apiServices.get(apiEndpoints.sellerShippingWithdraw+"?count="+count+"&page="+page);
        return response.data;
    }catch(err)
    {
        console.error("error while get shipping withdraw list",err)
        throw err
    }
}
//Withdrawal list with Shop ID
export const getShippingWithdrawListByShop = async(id,count,page) =>
    {
        try{
            const response = await apiServices.get(apiEndpoints.sellerShippingWithdrawByShop+id+"?count="+count+"&page="+page);
            return response.data;
        }catch(err)
        {
            console.error("error while get shipping withdraw list",err)
            throw err
        }
    }
//shipping withdraw Status changing
export const getShippingWithdrawStatus = async(id,status) =>
    {
        try{
            const response = await apiServices.get(apiEndpoints.updateStatusOfShippingWithdraw+id+"/"+status);
            return response.data;
        }catch(err)
        {
            console.error("error while update status of shipping withdraw",err)
            throw err
        }
    }

export default {getShippingWithdrawList,getShippingWithdrawListByShop,getShippingWithdrawStatus}
import { useEffect, useState } from "react";
import { useStore } from "zustand";
import orderStore from "../../../store/orderStore";

const useSellerOrders = () => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [seller, setSeller] = useState({ value: "all", label: "All" });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { fetchOrdersBySellerId, sellerOrders, totalSellerOrder } = useStore(orderStore);

  useEffect(() => {
    (async () => {
      try {
        await fetchOrdersBySellerId(seller.value == "all" ? "" : seller?.value, {
          page: currentPage,
          count: pageSize,
          keyword: searchKeyword,
        });
      } catch (error) {
        console.log(error);
      }
    })();
  }, [currentPage, pageSize, searchKeyword, seller]);

  function handlePageChange(page) {
    setCurrentPage(page);
  }

  function handlePageSizeChange(pageSize) {
    setPageSize(pageSize);
  }

  function handleSearch(value) {
    setSearchKeyword(value);
  }
  function selectSeller(value) {
    setSeller(value);
  }
  return {
    data: sellerOrders,
    totalRows: totalSellerOrder,
    handlePageChange,
    handlePageSizeChange,
    handleSearch,
    selectSeller,
  };
};

export default useSellerOrders;

import React, { useState } from "react";
import { Edit } from "react-feather";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import RModal from "../../../CommonElements/RModal/RModal";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import BusinessTypeStore from "../../../store/businessTypeStore";

const businessTypeSchema = z.object({
  business_type: z.string().min(1, "Business Type is required"),
});

const EditBusinessType = ({ businessType }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const store = BusinessTypeStore();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({
    mode: "all",
    resolver: zodResolver(businessTypeSchema),
    values: {
      business_type: businessType?.business_type,
    },
  });
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    reset();
  };
  const onSubmit = (data) => {
    store.updateBusinessType(businessType?.id, data);
    toggleModal();
  };

  return (
    <div>
      <Button
        className="p-1"
        style={{ width: "max-content" }}
        color="primary"
        size="sm"
        onClick={toggleModal}
      >
        <Edit />
      </Button>
      <RModal
        isOpen={isModalOpen}
        toggleModal={toggleModal}
        title="Edit Coupon"
        bodyContent={
          <>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <ModalBody>
              <FormGroup>
                  <Label for="name">Business Type:</Label>
                  <input
                    className="form-control"
                    type="text"
                    {...register("business_type")}
                  />
                  {errors.business_type && (
                    <span style={{ color: "red" }}>{errors.business_type.message}</span>
                  )}
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button type="submit" color="primary">
                  Save Changes
                </Button>
                <Button color="secondary" onClick={toggleModal}>
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          </>
        }
      />
    </div>
  );
};

export default EditBusinessType;

import apiEndpoints from "./apiEndpoints";
import apiServices from "./apiServices"

export const getAllCustomer = async(params) =>
{
    try{
        const queryString = new URLSearchParams(params).toString();
        const response = await apiServices.get(`${apiEndpoints.getCustomerList}?${queryString}`);
        return response.data
    }catch(err)
    {
        console.error("Fetching error in customer list:",err);
        throw err
    }
}

export default getAllCustomer
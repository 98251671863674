import React, { useState } from "react";
import DataTable from "react-data-table-component";
import Loader from "../Loader/Loader";

const Table = ({
  data,
  loading = false,
  columns,
  onPageChange,
  onPageSizeChange,
  pagination = true,
  totalRows, // Total number of rows (all records, used for pagination)
  pageSize, // Current page size (how many records per page)
  defaultPageSize = 10,
}) => {
  const handlePageChange = (newPage) => {
    if (onPageChange) onPageChange(newPage);
  };

  const handlePageSizeChange = (newPageSize) => {
    if (onPageSizeChange) onPageSizeChange(newPageSize);
  };

  return (
    <DataTable
      data={data}
      columns={columns}
      progressPending={loading}
      progressComponent={<Loader />}
      striped
      center
      pagination={pagination}
      paginationServer // Ensures external pagination logic
      paginationTotalRows={totalRows} // Total number of rows in the dataset
      paginationPerPage={pageSize} // Rows per page (controlled by state)
      paginationRowsPerPageOptions={[5, 10, 20, 50]} // Page size options
      onChangePage={handlePageChange} // Handles page changes
      onChangeRowsPerPage={handlePageSizeChange} // Handles page size changes
    />
  );
};

export default Table;

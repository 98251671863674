import { create } from "zustand";
import { createPaymentMethod, deletePaymentMethod, getAllPaymentMethods, paymentMethodToggleStatus, paymentMethodToggleTestMode, updatePaymentMethod } from "../Services/paymentMethodService";

const paymentMethodStore = create((set, get) => ({
  paymentMethods: [],
  totalPaymentMethod: 0,
  fetchAllMethods: async (params) => {
    try {
      const response = await getAllPaymentMethods(params);
      set({
        paymentMethods: response.data?.results,
        totalPaymentMethod: response.data?.count
      });
    } catch (err) {
      console.error("Error fetching payment method list:", err);
    }
  },

  createPaymentMethod: async (data) => {
    try {
      const response = await createPaymentMethod(data);
      await get().fetchAllMethods()
    } catch (error) {
      console.log("Error while creating payment method:", error);
    }
  },

  updatePaymentMethod: async (id, data) => {
    try {
      const response = await updatePaymentMethod(id, data);
      await get().fetchAllMethods()
    } catch (err) {
      console.error("Error while editing payment method:", err);
    }
  },

  deletePaymentMethod: async (id) => {
    try {
      await deletePaymentMethod(id);
      await get().fetchAllMethods()
    } catch (err) {
      console.error("Error while deleting payment method:", err);
    }
  },

  togglePaymentMethodStatus: async (id) => {
    try {
      await paymentMethodToggleStatus(id);
    } catch (err) {
      console.error("Error while updating payment method status:", err);
    }
  },

  togglePaymentMethodTestMode: async (id) => {
    try {
      await paymentMethodToggleTestMode(id);
    } catch (err) {
      console.error("Error while updating payment method status:", err);
    }
  },


}));

export default paymentMethodStore;

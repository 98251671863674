import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import { Col, Container, Row } from 'reactstrap';
import OrderHistoryTableCard from './OrderHistoryTableCard';
const SellerFinance = () => {
  
  return (
    <Fragment>
      <Breadcrumbs parent='Window' title='Shipping Withdraw' mainTitle='Shipping Withdraw' />
      <Container fluid={true} className='orderhistory'>
        <OrderHistoryTableCard />
      </Container>
    </Fragment>
  );
};

export default SellerFinance;

import apiEndpoints from "./apiEndpoints";
import apiServices from "./apiServices"

export const getAllPaymentMethods = async (params) => {
    try {
        const queryString = new URLSearchParams(params).toString();
        const response = await apiServices.get(`${apiEndpoints.getPaymentMethodInfo}?${queryString}`);
        return response.data
    } catch (err) {
        console.error("Fetching error in payment method list:", err);
    }
}



export const paymentMethodToggleStatus = async (id) => {
    try {
        const response = await apiServices.post(apiEndpoints.updatePaymentInfoStatus + id);
        return response.data
    } catch (err) {
        console.error("failed toggle status", err)
    }
}
export const paymentMethodToggleTestMode = async (id) => {
    try {
        const response = await apiServices.post(apiEndpoints.updatePaymentInfoTestMode + id);
        return response.data
    } catch (err) {
        console.error("failed toggle status", err)
    }
}

export const createPaymentMethod = async (data) => {
    try {
        const response = await apiServices.post(apiEndpoints.createPaymentMethod, data)
        return response.data
    }
    catch (err) {
        console.log("error in create Payment Method:", err)
        throw err
    }
}

export const updatePaymentMethod = async (param, data) => {
    try {
        const response = await apiServices.post(apiEndpoints.updatePaymentMethod + param, data)
        return response.data
    } catch (err) {
        console.error("Error while editing Payment Method type:", err);
        throw err
    }
}

export const deletePaymentMethod = async (param) => {
    try {
        const response = await apiServices.delete(apiEndpoints.deletePaymentMethod + param);
        return response;
    } catch (err) {
        console.error("Error while delete a payment Method:", err);
        throw err
    }
}
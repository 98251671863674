import { useEffect, useState } from "react";
import { useStore } from "zustand";
import addStore from "../../../store/addStore";

const useAdds = () => {
  const { fetchAddOne, fetchAddTwo, fetchAddThree, addOneList, addTwoList, addThreeList } = useStore(addStore);

  useEffect(() => {
    (async () => {
      try {
        await fetchAddOne();
        await fetchAddTwo();
        await fetchAddThree();
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return {
    addOneList, addTwoList, addThreeList
  };
};

export default useAdds;

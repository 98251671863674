import { P } from '../../../../AbstractElements';
import aamarPay from '../../../../assets/images/other-images/aamarPay.png'
import React, { Fragment } from 'react';
import { Col, Form, Input, Row } from 'reactstrap';

const PrintInvoice = () => {
    return (
        <Fragment>
            <Row className="mt-3">
                <Col md="8">
                    <div>
                        <P attrPara={{ className: 'legal' }}><strong>Note: Thank you for being with Window. We are committed to serve.</strong> Payment is expected within 3 days; please process this invoice within that time. There will be a 5% interest charge per month on late invoices.</P>
                    </div>
                </Col>
                <Col md="4">
                    <Form className="float-end invo-pal">
                        <Input type="image" src={aamarPay} name="submit" alt="aamarPay - The safer, easier way to pay online!" />
                    </Form>
                </Col>
            </Row>
        </Fragment>
    );
};
export default PrintInvoice;
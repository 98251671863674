import React, { useState } from "react";
import { Edit } from "react-feather";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import RModal from "../../../CommonElements/RModal/RModal";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import orderStore from "../../../store/orderStore";
import CSelect from "../../../CommonElements/CommonSelect/Select";

// Zod schema for validation
const statusSchema = z.object({
  status: z.object({
    value: z.string().min(1, { message: "Please select a status." }),
    label: z.string()
  }).refine(data => data.value && data.label, { message: "Status is required" }),
});

const UpdateOrderStatus = ({ order }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const orderStatus = order?.order_status
    ? { value: order?.order_status, label: order?.order_status }
    : null;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({
    mode: "all",
    resolver: zodResolver(statusSchema),
    values: {
      status: orderStatus,
    },
  });
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    reset();
  };
  const store = orderStore();

  const onSubmit = (data) => {
    let status = data.status.value
    store.updateOrderStatus(order?.order_id, status, true);
    toggleModal();
  };

  return (
    <div>
      <Edit style={{ cursor: "pointer" }} onClick={toggleModal} />
      <RModal
        isOpen={isModalOpen}
        toggleModal={toggleModal}
        title="Update Status"
        bodyContent={
          <>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <ModalBody>
                <FormGroup>
                  <Label for="name">Order Status:</Label>
                  <CSelect
                    control={control}
                    name="status"
                    options={[
                      {
                        label: "Pending",
                        value: "Pending"
                      },
                      {
                        label: "Shipped",
                        value: "Shipped"
                      },
                      {
                        label: "Delivered",
                        value: "Delivered"
                      },
                      {
                        label: "Cancelled",
                        value: "Cancelled"
                      },
                    ]}
                  />
                  {errors.status && (
                    <span style={{ color: "red" }}>{errors.status.message}</span>
                  )}
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button type="submit" color="primary">
                  Save Changes
                </Button>
                <Button color="secondary" onClick={toggleModal}>
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          </>
        }
      />
    </div>
  );
};

export default UpdateOrderStatus
  ;

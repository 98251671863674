import { Button, Col, Media, Row } from "reactstrap";
import { Image } from "../../../AbstractElements";
import moment from "moment";
import ToggleBtn from "../../../CommonElements/toggleButton";
import { notificationType, showNotification } from "../../../utils/notification";
import couponStore from "../../../store/couponStore";
import { Edit, Trash } from "react-feather";
import { useState } from "react";
import EditCoupon from "./edit-coupon";
import DeleteCoupon from "./delete-coupon";

export const tableColumns = [
    {
        name: "Name",
        selector: (row) => row.coupon_code,
        sortable: true,
        cell: (row) => (
            <span className="f-w-700">{row.coupon_code}</span>
        ),
    },
    {
        name: "Coupon Type",
        selector: (row) => row.coupon_type,
        sortable: true,
        cell: (row) => (
            <span className="f-w-500">{`${row.coupon_value}% ${row.coupon_type}`}</span>
        ),
    },
    {
        name: "Time Period",
        selector: (row) => row.start_date,
        sortable: true,
        cell: (row) => {
            const formattedStartDate = moment(row.start_date).format('MMMM Do YYYY');
            const formattedEndDate = moment(row.end_date).format('MMMM Do YYYY');
            return (
                <div className="d-flex flex-column">
                    <div className="badge badge-light-primary">{formattedStartDate}</div>
                    <div className="f-w-500 text-center">To</div>
                    <div className="badge badge-light-primary">{formattedEndDate}</div>
                </div>
            )
        },
    },
    {
        name: "Use Limit",
        selector: (row) => row.usage_limit,
        sortable: true,
        center: true,
        cell: (row) => (
            <span className="badge badge-light-primary">{row.usage_limit}</span>
        ),
    },
    {
        name: "Total Used",
        selector: (row) => row.total_used,
        sortable: true,
        center: true,
        cell: (row) => (
            <span className="badge badge-light-primary">{row.total_used} Times</span>
        ),
    },
    {
        name: "Status",
        selector: (row) => row.status,
        cell: (row) => {
            const { toggleCouponStatusById, deleteCouponById } = couponStore();

            const handleToggleStatus = async (id) => {
                try {
                    await toggleCouponStatusById(id);
                    showNotification({ type: notificationType.SUCCESS, message: "Status updated!" })
                } catch (err) {
                    showNotification({ type: notificationType.DANGER, message: "Status update Failed!" })
                    console.error("error while toggling status: ", err)
                }
            }

            return (
                <ToggleBtn
                    value={row.status}
                    onToggle={() => handleToggleStatus(row.id)}
                />
            )
        },
    },
    {
        name: "Actions",
        sortable: true,
        center: true,
        cell: (row) => {
            return (
                <div className="d-flex" style={{ gap: "6px" }}>
                    <EditCoupon coupon={row}/>
                    <DeleteCoupon coupon={row}/>
                </div>
            )
        },
    },
];

import { Image } from "../../../AbstractElements";
import { Media } from "reactstrap";
import { toggleCommission, toggleFeature, toggleStatus } from "../../../Services/businessService";
import ToggleBtn from "../../../CommonElements/toggleButton";
import { notificationType, showNotification } from "../../../utils/notification";

const handleToggleStatus = async (data) => {
    try {
        await toggleStatus(data);
        showNotification({ type: notificationType.SUCCESS, message: "Status updated!" })
    } catch (err) {
        showNotification({ type: notificationType.DANGER, message: "Status update Failed!" })
        console.error("error while toggling status: ", err)
    }
}
const handleToggleFeature = async (data) => {
    try {
        await toggleFeature(data);
        showNotification({ type: notificationType.SUCCESS, message: "Feature updated!" })
    } catch (err) {
        showNotification({ type: notificationType.DANGER, message: "Feature update Failed!" })
        console.error("error while toggling feature: ", err)
    }
}
const handleToggleCommission = async (data) => {
    try {
        await toggleCommission(data);
        showNotification({ type: notificationType.SUCCESS, message: "Commission updated!" })
    } catch (err) {
        showNotification({ type: notificationType.DANGER, message: "Commission update Failed!" })
        console.error("error while toggling commission: ", err)
    }
}
export const tableColumns = [
    {
        name: "Name",
        selector: (row) => row.business_name,
        sortable: true,
        center: true,
        cell: (row) => (
            <Media className="d-flex">
                <Image
                    attrImage={{
                        className: "rounded-circle img-50 me-3",
                        src: row.logo,
                        alt: "business-logo",
                    }}
                />
                <Media body className="align-self-center">
                    <div>{row.business_name}</div>
                </Media>
            </Media>
        ),
    },
    {
        name: "Business Contact",
        selector: (row) => row.business_contact,
        sortable: true,
        center: true,
        cell: (row) => (
            <span className="f-w-700 font-success">{row.business_contact}</span>
        ),
    },
    {
        name: "Payout Method",
        selector: (row) => row.payout_method,
        sortable: true,
        center: true,
        cell: (row) => (
            <span className="badge badge-light-primary">{row.payout_method}</span>
        ),
    },
    {
        name: "Featured",
        selector: (row) => row.featured,
        center: true,
        cell: (row) => (
            <ToggleBtn
                value={row.featured}
                onToggle={() => handleToggleFeature(row.id)}
            />
        ),
    },
    {
        name: "Status",
        selector: (row) => row.business_status,
        center: true,
        cell: (row) => (
            <ToggleBtn
                value={row.business_status}
                onToggle={() => handleToggleStatus(row.id)}
            />
        ),
    },
    {
        name: "Commission",
        selector: (row) => row.commission_status,
        center: true,
        cell: (row) => (
            <ToggleBtn
                value={row.commission_status}
                onToggle={() => handleToggleCommission(row.id)}
            />
        ),
    },
];

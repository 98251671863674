import React, { useState } from "react";
import { Edit, Plus } from "react-feather";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import RModal from "../../../CommonElements/RModal/RModal";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { getAllCategory } from "../../../Services/productCategoryService";
import { getCommonParams } from "../../../utils/helper";
import CSelect from "../../../CommonElements/CommonSelect/Select";
import CAsyncSelect from "../../../CommonElements/CommonSelect/AsyncSelect";
import dealsOfTheDay from "../../../store/dealsOfTheDay";
import { getAllProductList } from "../../../Services/productService";

// Zod schema for validation
const productSchema = z.object({
  product: z
    .object({
      label: z.string(),
      value: z.string().or(z.number()),
    })
    .nullable()
    .refine((val) => val !== null, {
      message: "Please select a product",
    }),
  sale_price: z
    .string()
    .refine((val) => !isNaN(parseFloat(val)), {
      message: "Sale price must be a number",
    })
    .transform((val) => parseFloat(val))
    .refine((val) => val > 0, {
      message: "Sale price must be a positive number",
    }),
});

const AddDealsOfTheDay = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const store = dealsOfTheDay();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({
    mode: "all",
    resolver: zodResolver(productSchema),
    defaultValues: {
      product: null,
      sale_price: "",
    },
  });

  const onSubmit = (data) => {
    console.log(data);
    const product = data?.product?.value;
    const newData = {
      ...data,
      product: product,
    };
    store.createDeal(newData);
    reset();
    toggleModal();
  };
  console.log(errors);
  return (
    <div>
      <Button
        className="p-1 d-flex align-items-center"
        style={{ width: "max-content" }}
        color="primary"
        size="sm"
        onClick={toggleModal}
      >
        <Plus /> Create Deal
      </Button>
      <RModal
        isOpen={isModalOpen}
        toggleModal={toggleModal}
        title="Add Coupon"
        bodyContent={
          <>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <ModalBody>
                <FormGroup>
                  <Label for="product">Product:</Label>
                  <CAsyncSelect
                    name="product"
                    control={control}
                    fetchOptions={async (value) => {
                      return getAllProductList(getCommonParams(value));
                    }}
                    modifyOptions={(products) => {
                      return products.map((product) => ({
                        label: product.name,
                        value: product.id,
                      }));
                    }}
                  />
                  {errors.product && (
                    <span style={{ color: "red" }}>
                      {errors.product.message}
                    </span>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="sale_price">Sale Price:</Label>
                  <input
                    className="form-control"
                    type="number"
                    id="sale_price"
                    {...register("sale_price")}
                  />
                  {errors.sale_price && (
                    <span style={{ color: "red" }}>
                      {errors.sale_price.message}
                    </span>
                  )}
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button type="submit" color="primary">
                  Create Deal
                </Button>
                <Button color="secondary" onClick={toggleModal}>
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          </>
        }
      />
    </div>
  );
};

export default AddDealsOfTheDay;

import { Fragment } from "react";
import AddSectionOne from "./components/add-section-one";
import AddSectionTwo from "./components/add-section-two";
import AddSectionThree from "./components/add-section-three";
import useAdds from "./hooks/useAdds";

const HomeAddsPage = () => {
  const {
    addOneList, addTwoList, addThreeList
  } = useAdds();
  return (
    <Fragment>
      <AddSectionOne addOne={addOneList} />
      <AddSectionTwo addTwo={addTwoList} />
      <AddSectionThree addThree={addThreeList} />
    </Fragment>
  );
};

export default HomeAddsPage;

import { Container, Row, Col, Card, CardBody } from "reactstrap";
import HeaderCard from "../../Components/Common/Component/HeaderCard";
import Table from "../../CommonElements/Table/Table";
import { tableColumns } from "./components/columns";
import { Breadcrumbs } from "../../AbstractElements";
import { Fragment } from "react";
import TableHeader from "../../CommonElements/TableHeader/TableHeader";
import CCardHeader from "../../CommonElements/CardHeader/CardHeader";
import useBusinessTypes from "./hooks/useBusinessTypes";
import AddBusinessType from "./components/add-business-type";

const BusinessTypePage = () => {
  const {
    data,
    totalRows,
    handlePageChange,
    handlePageSizeChange,
    handleSearch,
  } = useBusinessTypes();
  return (
    <Fragment>
      <Breadcrumbs parent="Home" title="Business Type" mainTitle="Business Type" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CCardHeader title="Business Types" rightContent={<AddBusinessType />} />
              <TableHeader onSearch={handleSearch} />
              <CardBody>
                <Table
                  data={data}
                  columns={tableColumns}
                  onPageChange={handlePageChange}
                  onPageSizeChange={handlePageSizeChange}
                  totalRows={totalRows}
                  pageSize={10}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default BusinessTypePage;

import { create } from "zustand";
import { getPgHistory, getPgLogs } from "../Services/paymentGatewayService";

const pgStore = create((set, get) => ({
  pgHistory: [],
  totalPgHistory: 0,
  pgLogs: [],
  totalPgLogs: 0,

  fetchPgHistory: async (params) => {
    try {
      const response = await getPgHistory(params);
      set({
        pgHistory: response.data?.results,
        totalPgHistory: response?.data?.count,
      });
    } catch (err) {
      console.error("Error while fetching pg history data", err);
    }
  },

  fetchPgLogs: async (params) => {
    try {
      const response = await getPgLogs(params);
      set({
        pgLogs: response.data?.results,
        totalPgLogs: response?.data?.count,
      });
    } catch (err) {
      console.error("Error while fetching pg logs data", err);
    }
  },
}));

export default pgStore;

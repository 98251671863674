import { useEffect, useState } from "react";
import { useStore } from "zustand";
import productStore from "../../../store/productStore";

const useProducts = () => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [business, setBusiness] = useState({ value: "all", label: "All" });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { fetchAllProducts, totalProducts, products } = useStore(productStore);

  useEffect(() => {
    (async () => {
      try {
        await fetchAllProducts({
          page: currentPage,
          count: pageSize,
          keyword: searchKeyword,
          business: business.value == "all" ? "" : business?.value,
        });
      } catch (error) {
        console.log(error);
      }
    })();
  }, [currentPage, pageSize, searchKeyword, business]);

  function handlePageChange(page) {
    setCurrentPage(page);
  }

  function handlePageSizeChange(pageSize) {
    setPageSize(pageSize);
  }

  function handleSearch(value) {
    setSearchKeyword(value);
  }
  function selectBusiness(value) {
    setBusiness(value);
  }
  return {
    data: products,
    totalRows: totalProducts,
    handlePageChange,
    handlePageSizeChange,
    handleSearch,
    selectBusiness,
  };
};

export default useProducts;

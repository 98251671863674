import { create } from "zustand";
import {
  allCategory,
  createCategory,
  deleteCategory,
  editCategory,
  getAllCategory,
  toggleFeatured,
} from "../Services/productCategoryService";

const categoryStore = create((set, get) => ({
  categories: [],
  totalCategory: 0,
  fetchAllCategory: async (params) => {
    try {
      const response = await getAllCategory(params);
      set((state) => ({
        categories: response.data?.results,
        totalCategory: response.data?.count,
      }));
    } catch (err) {
      console.error("Error fetching category list:", err);
    }
  },
  createCategory: async (data) => {
    try {
      const response = await createCategory(data);
      await get().fetchAllCategory();
    } catch (error) {
      console.log("Error while creating category:", error);
    }
  },
  updateCategory: async (id, data) => {
    try {
      await editCategory(id, data);
      await get().fetchAllCategory();
    } catch (error) {
      console.error("update category", error);
    }
  },
  deleteCategory: async (id) => {
    try {
      await deleteCategory(id);
      await get().fetchAllCategory();
    } catch (err) {
      console.error("Error while deleting category:", err);
    }
  },
  toggleCategoryFeatured: async (id) => {
    try {
      await toggleFeatured(id);
    } catch (err) {
      console.error("Error while deleting category:", err);
    }
  },
}));

export default categoryStore;

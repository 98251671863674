import React, { useState } from "react";
import { Edit, Plus } from "react-feather";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import RModal from "../../../CommonElements/RModal/RModal";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

// Zod schema for validation
import UploadImage from "../../../CommonElements/UploadImage/upload-image";
import sliderStore from "../../../store/sliderStore";
import addStore from "../../../store/addStore";
import CSelect from "../../../CommonElements/CommonSelect/Select";

const sliderSchema = z.object({
  number: z.object({
    value: z.string().min(1, { message: "Please select a One." }),
    label: z.string()
  }).refine(data => data.value && data.label, { message: "Add number is required" }),
  ad_url: z.string().min(1, "Banner url is required"),
  ad_image: z.string().min(1, "Image is required"),
});

const AddAdd = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const store = addStore();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({
    mode: "all",
    resolver: zodResolver(sliderSchema),
    defaultValues: {
      ad_url: "",
      ad_image: "",
    },
  });

  const onSubmit = (data) => {
    const id = data?.number?.value;
    store.updateAddThree(id,data);
    toggleModal();
  };
  return (
    <div>
      <Button
        className="p-1 d-flex align-items-center"
        style={{ width: "max-content" }}
        color="primary"
        size="sm"
        onClick={toggleModal}
      >
        <Plus /> Add/Update Add
      </Button>
      <RModal
        isOpen={isModalOpen}
        toggleModal={toggleModal}
        title="Add/Update Add"
        bodyContent={
          <>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <ModalBody>
                <FormGroup>
                  <Label for="number">Select Add:</Label>
                  <CSelect
                    name="number"
                    control={control}
                    options={[
                      {
                        label: "Add One",
                        value: "1",
                      },
                      {
                        label: "Add Two",
                        value: "2",
                      }
                    ]}
                  />
                  {errors.number && <span style={{ color: 'red' }}>{errors.number.message}</span>}
                </FormGroup>
                <FormGroup>
                  <Label for="name">Add Url:</Label>
                  <input
                    className="form-control"
                    type="text"
                    {...register("ad_url")}
                  />
                  {errors.ad_url && (
                    <span style={{ color: "red" }}>{errors.ad_url.message}</span>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="image">Add Image:</Label>
                  <UploadImage control={control} name="ad_image" />
                  {errors.ad_image && (
                    <span style={{ color: "red" }}>{errors.ad_image.message}</span>
                  )}
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button type="submit" color="primary">
                  Add/Update Add
                </Button>
                <Button color="secondary" onClick={toggleModal}>
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          </>
        }
      />
    </div>
  );
};

export default AddAdd;

import { create } from "zustand";
import { getSellerFinanceById } from "../Services/financeServices";

const financeStore = create((set, get) => ({
  updatePageSizeData: 1,
  updatePageCountData: 10,
  updateBusinessIdData: 0,
  totalRows: 0,
  allFinanceList: [],

  // fetch and store order list 
  fetchAllFinanceList: async () => {
    try {
      const { updatePageSizeData, updatePageCountData, updateBusinessIdData } = get();
      const response = await getSellerFinanceById(updateBusinessIdData, updatePageCountData, updatePageSizeData);
      if (response.code === 200) {
        set((state) => ({
          allFinanceList: response.results,
          totalRows: response.count,
          errorMessage: "",
        }));
      } else {
        set((state) => ({
          errorMessage: response.message,
        }));
      }
    } catch (err) {
      console.log("Error while updating offer:", err);
      throw err;
    }
  },
  //update business iD and fetch order list
  updateBusinessId: (businessId) => {
    set({ updateBusinessIdData: businessId });
    get().fetchAllFinanceList();
  },
  updatePaginationData: (pageSize, countData) => {
    set({
      updatePageSizeData: pageSize,
      updatePageCountData: countData
    });
    get().fetchAllFinanceList();
  },

}));

export default financeStore;

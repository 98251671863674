import { create } from 'zustand';
import {
  getAllCoupon,
  getCouponInfo,
  createCoupon,
  deleteCoupon,
  toggleCouponStatus,
  updateCoupon
} from '../Services/couponService'; // Adjust the path as needed
import { notificationType, showNotification } from '../utils/notification';

const couponStore = create((set, get) => ({
  totalCoupons: 0,
  coupons: [],
  singleCoupon: null,
  message: null,

  // Fetch all coupons
  fetchAllCoupons: async (params) => {
    try {
      const response = await getAllCoupon(params);
      set(() => ({
        coupons: response.data?.results,
        totalCoupons:response.data?.count
      }));
    } catch (err) {
      console.error("Error fetching all coupons:", err);
      throw err;
    }
  },

  // Fetch single coupon info
  fetchCouponInfo: async (id) => {
    try {
      const response = await getCouponInfo(id);
      set(() => ({
        singleCoupon: response,
      }));
    } catch (err) {
      console.error("Error fetching coupon info:", err);
      throw err;
    }
  },
  clearMessage: () => {
    setTimeout(() => {
      set(() => ({
        message: null,
      }));
    }, 3000); // Hide the message after 3 seconds
  },

  // Create a new coupon
  createNewCoupon: async (data) => {
    try {
      const response = await createCoupon(data);
      await get().fetchAllCoupons();
    } catch (err) {
      showNotification({type:notificationType.DANGER,message:err.toString()})
    }
  },

  // Update an existing coupon
  updateCoupon: async (id, data) => {
    try {
      const response = await updateCoupon(id, data);
      set(() => ({
        message: response.message || "Coupon updated successfully",
      }));
      // Optionally, refresh the coupon list after updating
      await get().fetchAllCoupons();
    } catch (err) {
      console.error("Error updating coupon:", err);
      throw err;
    }
  },

  // Delete a coupon
  deleteCouponById: async (id) => {
    try {
      const response = await deleteCoupon(id);
      set(() => ({
        message: response.message || "Coupon deleted successfully",
      }));
      // Optionally, refresh the coupon list after deletion
      await get().fetchAllCoupons();
    } catch (err) {
      console.error("Error deleting coupon:", err);
      throw err;
    }
  },

  // Toggle coupon status
  toggleCouponStatusById: async (id) => {
    try {
      const response = await toggleCouponStatus(id);
      set(() => ({
        message: response.message || "Coupon status toggled successfully",
      }));
      // Optionally, refresh the coupon list after status toggle
      await get().fetchAllCoupons();
    } catch (err) {
      console.error("Error toggling coupon status:", err);
      throw err;
    }
  },
}));

export default couponStore;

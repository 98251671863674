import React, { useRef, useState } from "react";
import { Edit } from "react-feather";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import RModal from "../../../CommonElements/RModal/RModal";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import blogStore from "../../../store/blogStore";
import SimpleMdeReact from "react-simplemde-editor";
import "easymde/dist/easymde.min.css"; // Ensure this is imported

// Zod schema for validation
const blogSchema = z.object({
  title: z.string().min(1, "Title is required"),
});

const EditBlog = ({ blog }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { updateBlog } = blogStore();
  const descriptionRef = useRef(blog?.description ?? "");

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    descriptionRef.current = blog?.description || '';
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({
    mode: "all",
    resolver: zodResolver(blogSchema),
    defaultValues: {
      title: blog?.title,
    },
  });

  const onSubmit = (values) => {
    const data = {
      ...values,
      description: descriptionRef.current,
    };
    updateBlog(blog?.id, data);
    toggleModal();
  };

  return (
    <div>
      <Button
        className="p-1"
        style={{ width: "max-content" }}
        color="primary"
        size="sm"
        onClick={toggleModal}
      >
        <Edit />
      </Button>
      <RModal
        isOpen={isModalOpen}
        toggleModal={toggleModal}
        title="Edit Coupon"
        size="xl"
        bodyContent={
          <Form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody>
              <FormGroup>
                <Label for="title">Title:</Label>
                <input
                  className="form-control"
                  type="text"
                  {...register("title")}
                />
                {errors.title && (
                  <span style={{ color: "red" }}>{errors.title.message}</span>
                )}
              </FormGroup>
              <FormGroup>
                <Label>Content :</Label>
                <SimpleMdeReact
                  value={descriptionRef.current}
                  onChange={(value) => {
                    descriptionRef.current = value;
                  }}
                  options={{
                    autofocus: true,
                    spellChecker: false,
                  }}
                />
                {descriptionRef.current == "" && (
                  <span style={{ color: "red" }}>Content is Required.</span>
                )}
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button type="submit" color="primary">
                Save Changes
              </Button>
              <Button color="secondary" onClick={toggleModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        }
      />
    </div>
  );
};

export default EditBlog;
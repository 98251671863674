import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { Widgets2Data, Widgets2Data2, WidgetsData, WidgetsData2, WidgetsData3, WidgetsData4 } from '../../../Data/DefaultDashboard';
import Widgets1 from '../../Common/CommonWidgets/Widgets1';
import Widgets2 from '../../Common/CommonWidgets/Widgets2';
import authStore from '../../../store/authStore';

const WidgetsWrapper = () => {
  const { statistics, getStatistics } = authStore();
  useEffect(() => {
    getStatistics();
  }, [])
  let data1 = {
    title: 'Total Orders',
    gros: 50,
    total: statistics.total_orders,
    color: 'secondary',
    icon: 'cart',
  }
  let data2 = {
    title: 'Total Sellers',
    gros: 50,
    total: statistics.total_sellers,
    color: 'warning',
    icon: 'fill-user',
  }
  let data3 = {
    title: 'Total Products',
    gros: 50,
    total: statistics.total_products,
    color: 'primary',
    icon: 'fill-widget',
  }
  let data4 = {
    title: 'Total User',
    gros: 50,
    total: statistics.total_users,
    color: 'success',
    icon: 'fill-contact',
  }
  let data5 = {
    title: 'Total Business',
    gros: 50,
    total: statistics.total_businesses,
    color: 'warning',
    icon: 'cart',
  }
  return (
    <>
      <Col xxl='auto' xl='3' sm='6' className='box-col-6'>
        <Row>
          <Col xl='12'>
            <Widgets1 data={data1} />
          </Col>
          <Col xl='12'>
            <Widgets1 data={data2} />
          </Col>
        </Row>
      </Col>
      <Col xxl='auto' xl='3' sm='6' className='box-col-6'>
        <Row>
          <Col xl='12'>
            <Widgets1 data={data3} />
          </Col>
          <Col xl='12'>
            <Widgets1 data={data4} />
          </Col>
        </Row>
      </Col>
      <Col xxl='auto' xl='3' sm='6' className='box-col-6'>
        <Row>
          <Col xl='12'>
            <Widgets1 data={data5} />
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default WidgetsWrapper;

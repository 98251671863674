import { create, set } from "zustand";
import { getAllCustomer } from "../Services/customerService";

const customerStore = create((set) => ({
  customers: [],
  totalCustomers: 0,
  fetchAllCustomer: async (params) => {
    try {
      const response = await getAllCustomer(params);
      set((state) => ({
        customers: response.data?.results,
        totalCustomers: response.data?.count,
      }));
    } catch (err) {
      console.error("Error fetching customer list:", err);
    }
  },
}));

export default customerStore;

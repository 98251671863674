
import apiEndpoints from "./apiEndpoints";
import apiServices from "./apiServices"

export const getAllDeals = async (params) => {
    try {
        const queryString = new URLSearchParams(params).toString();
        const response = await apiServices.get(`${apiEndpoints.getDeals}?${queryString}`);
        return response.data
    } catch (err) {
        console.error("Fetching error in all offer services:", err);
        throw err
    }
}

export const createDeal = async (data) => {
    try {
        const response = await apiServices.post(apiEndpoints.createDeal, data)
        return response.data
    }
    catch (err) {
        console.log("error in create offer:", err)
        throw err
    }
}
export const updateDeal = async (id, data) => {
    try {
        const response = await apiServices.post(apiEndpoints.updateOfferSetting, data)
        return response.data
    }
    catch (err) {
        console.log("error in create offer:", err)
        throw err
    }
}

export const deleteDeal = async (param) => {
    try {
        const response = await apiServices.delete(apiEndpoints.deleteDeal + param);
        return response;
    } catch (err) {
        console.error("Error while delete a offer:", err);
        throw err
    }
}

export const toggleDealStatus = async (param) => {
    try {
        const response = await apiServices.get(apiEndpoints.toggleDealStatus + param);
        return response.data
    } catch (err) {
        console.error("Error while toggling offer status:", err)
        throw err;
    }
}


import apiEndpoints from "./apiEndpoints";
import apiServices from "./apiServices"

export const getSliders = async (params) => {
    try {
        const queryString = new URLSearchParams(params).toString();
        const response = await apiServices.get(`${apiEndpoints.get_all_slider}?${queryString}`);
        return response.data
    } catch (err) {
        console.error("Fetching error in banner:", err);
    }
}

export const createSlider = async (data) => {
    try {
        const response = await apiServices.post(apiEndpoints.add_slider, data)
        return response.data
    }
    catch (err) {
        console.log("error in create banner:", err)
    }
}

export const deleteSlider = async (param) => {
    try {
        const response = await apiServices.delete(apiEndpoints.delete_slider + param);
        return response;
    } catch (err) {
        console.error("Error while delete a banner:", err);
    }
}


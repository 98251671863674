import React, { Fragment, useEffect } from 'react';
import PrintComponent from './Print';
import { Breadcrumbs } from '../../../../AbstractElements';
import orderStore from '../../../../store/orderStore';
import { useParams } from 'react-router';
import DropDown from './statusDropDown';
import MainOrder from './MainOrder';

const InvoiceContain = () => {
  const { id } = useParams();
  const { fetchInvoiceFromOrderId, updatedOrderIdInvoiceList } = orderStore();

  useEffect(() => {
    fetchInvoiceFromOrderId(id);
  }, [fetchInvoiceFromOrderId, id]);

  return (
    <Fragment>
      <Breadcrumbs parent='Ecommerce' title='Invoice' mainTitle='Invoice' />
      
      <table style={{ width: '100%' }}>
        <tbody>
          <tr>
            <td style={{ width: '50%', verticalAlign: 'top', paddingLeft:"40px" }}>
              <DropDown />
            </td>
            <td style={{ width: '50%', verticalAlign: 'top', paddingLeft:"40px" }}>
              <MainOrder  />
            </td>
          </tr>
        </tbody>
      </table>
      
      <PrintComponent data={updatedOrderIdInvoiceList} />
    </Fragment>
  );
};

export default InvoiceContain;

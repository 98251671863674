
import apiEndpoints from "./apiEndpoints";
import apiServices from "./apiServices"

export const getAllBlogs = async (params) => {
    try {
        const queryString = new URLSearchParams(params).toString();
        const response = await apiServices.get(`${apiEndpoints.getBlogs}?${queryString}`);
        return response.data
    } catch (err) {
        console.error("Fetching error in all blog services:", err);
        throw err
    }
}

export const createBlog = async (data) => {
    try {
        const response = await apiServices.post(apiEndpoints.createBlog, data)
        return response.data
    }
    catch (err) {
        console.log("error in create blog:", err)
        throw err
    }
}

export const updateBlog = async (param, data) => {
    try {
        const response = await apiServices.put(apiEndpoints.updateBlog + param, data)
        return response.data
    } catch (err) {
        console.error("Error while updating blog:", err);
        throw err
    }
}

export const deleteBlog = async (param) => {
    try {
        const response = await apiServices.post(apiEndpoints.deleteBlog + param);
        return response;
    } catch (err) {
        console.error("Error while delete a blog:", err);
        throw err
    }
}

export const getBlogDetails = async (param) => {
    try {
        const response = await apiServices.get(apiEndpoints.getBlogDetails + param);
        return response.data
    } catch (err) {
        console.error("Fetching error in get blog info:", err);
        throw err
    }
}
import { toast } from "react-toastify";

export const showNotification = ({ type, message }) => {
    switch (type) {
        case 'success':
            toast.success(message ?? 'Success Notification !', {
                position: toast.POSITION.TOP_RIGHT
            });
            break;
        case 'info':
            toast.info(message ?? 'Info Notification !', {
                position: toast.POSITION.TOP_RIGHT
            });
            break;
        case 'warning':
            toast.warn(message ?? 'Warning Notification !', {
                position: toast.POSITION.TOP_RIGHT
            });
            break;
        case 'danger':
            toast.error(message ?? 'Danger Notification !', {
                position: toast.POSITION.TOP_RIGHT
            });
            break;
        default:
            break;
    }
};

export const notificationType = {
    SUCCESS: "success",
    INFO: "info",
    WARNING: "warning",
    DANGER: "danger",
}
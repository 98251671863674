import { create } from "zustand";
import { editUserinfo, getUserInfo } from "../Services/userService";
import { notificationType, showNotification } from "../utils/notification";

const userStore = create((set, get) => ({
  userInfo: {},
  updating: false,
  fetchUserInfo: async () => {
    try {
      const response = await getUserInfo();
      set({
        userInfo: response.data
      });
    } catch (err) {
      console.log("Error fetching user list:", err);
    }
  },
  updateUser: async (data) => {
    try {
      set({
        updating: true
      });
      const response = await editUserinfo(data);
      showNotification({
        type: notificationType.SUCCESS,
        message: "User updated!",
      });
      set({
        updating: false
      });
      await get().fetchUserInfo();
    } catch (err) {
      showNotification({
        type: notificationType.DANGER,
        message: "User update Failed!",
      });
      set({
        updating: false
      });
      console.error("Error while editing user", err);
    }
  },
}));

export default userStore;

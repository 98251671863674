import { create } from "zustand";
import {
  getAllProductList,
  getSingleProductInfo,
  productToggleStatus,
  advanceToggleStatus,
  getProductByBusiness,
} from "../Services/productService";

const productStore = create((set) => ({
  products: [],
  totalProducts: 0,

  fetchAllProducts: async (params) => {
    try {
      const response = await getAllProductList(params);
      set(() => ({
        products: response.data?.results,
        totalProducts: response.data?.count,
      }));
    } catch (err) {
      console.error("Error fetching all products:", err);
    }
  },
  toggleProductStatus: async (id) => {
    try {
      await productToggleStatus(id);
    } catch (err) {
      console.error("Error toggling product status:", err);
    }
  },
  toggleAdvancePayStatus: async (id) => {
    try {
      const response = await advanceToggleStatus(id);
    } catch (err) {
      console.error("Error toggling advance pay status:", err);
    }
  },
}));

export default productStore;

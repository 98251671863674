import { useStore } from "zustand";
import userStore from "../../../store/userStore";
import { useEffect, useState } from "react";

const useUser = () => {
    const [loading, setLoading] = useState(false);
    const { userInfo, fetchUserInfo, updating, updateUser } = useStore(userStore);

    useEffect(() => {
        (async () => {
            try {
                await fetchUserInfo();
            } catch (error) {
                console.log(error);
            }
        })();
    }, []);
    return {
        user: userInfo,
        loading: loading,
        updating: updating,
        updateUserInfo: updateUser,
    }
};

export default useUser;
import React, { Fragment, useEffect, useState } from 'react';
import { Btn, H4, P, Image } from '../../AbstractElements';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import authStore from '../../store/authStore'
import logoWhite from '../../assets/images/logo/logo.png';
import logoDark from '../../assets/images/logo/logo_dark.png';
import { notificationType, showNotification } from '../../utils/notification';

const LoginForm = ({ logoClassMain }) => {
  //get auth store 
  const store = authStore();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [togglePassword, setTogglePassword] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false)

  //handle login functionality
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await store.login();
      setLoggedIn(true);
    } catch (err) {
      setErrorMessage(store.errorMessage);
      setLoggedIn(false);
    }
  }

  useEffect(() => {
    if (loggedIn) {
      navigate("/dashboard/default/");
    }
  }, [loggedIn, navigate])
  return (
    <Fragment>
      <div className='login-card'>
        <div>
          <div>
            <Link className={`logo d-flex justify-content-center ${logoClassMain ? logoClassMain : ''}`} to={process.env.PUBLIC_URL}>
              <Image attrImage={{ className: 'img-fluid for-light ', src: logoWhite, alt: 'looginpage', style: { width: "250px" } }} />
              <Image attrImage={{ className: 'img-fluid for-dark', src: logoDark, alt: 'looginpage' }} />
            </Link>
          </div>
          <div className='login-main'>
            <Form onSubmit={handleLogin} className='theme-form login-form'>
              <H4>Sign in to Admin Account</H4>
              <P>Enter your email & password to login</P>
              <FormGroup>
                <Label className='col-form-label m-0'>Email Address</Label>
                <Input className='form-control' onChange={store.updateLoginForm} type='email' name="email" value={store.loginForm.email} required placeholder='Test@gmail.com' />
              </FormGroup>
              <FormGroup className='position-relative'>
                <Label className='col-form-label m-0'>Password</Label>
                <div className='position-relative'>
                  <Input className='form-control' onChange={store.updateLoginForm} type={togglePassword ? 'text' : 'password'} name='password' value={store.loginForm.password} required placeholder='*********' />
                  <div className='show-hide' onClick={() => setTogglePassword(!togglePassword)}>
                    <span className={togglePassword ? '' : 'show'}></span>
                  </div>
                </div>
              </FormGroup>
              <FormGroup className='position-relative'>
                {/* <div className='checkbox'>
                  <Input id='checkbox1' type='checkbox' />
                  <Label className='text-muted' for='checkbox1'>
                    Remember password
                  </Label>
                </div>
                <Link className='link' to={`${process.env.PUBLIC_URL}/pages/authentication/forget-pwd`}>
                  Forgot password?
                </Link> */}
              </FormGroup>
              <FormGroup>
                <Btn attrBtn={{ className: 'd-block w-100 mt-2', color: 'primary', type: 'submit' }}>
                  {
                    store.signing ?
                      "Signing..."
                      :
                      "Sign in"
                  }
                </Btn>
              </FormGroup>
              {/* <P attrPara={{ className: 'text-center mb-0 ' }}>
                Don't have account?
                <Link className='ms-2' to={`${process.env.PUBLIC_URL}/pages/authentication/register-simple`}>
                  Create Account
                </Link>
              </P> */}
            </Form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default LoginForm;

import apiServices from "./apiServices";
import apiEndpoints from "./apiEndpoints";
//handle login login 
export const login = async (data) => {
    try {
        const response = await apiServices.post(apiEndpoints.login, data);
        return response.data;
    } catch (error) {
        throw error
    }
};
export const getStatistics = async () => {
    try {
        const response = await apiServices.get(apiEndpoints.getStatistics);
        return response.data;
    } catch (error) {
        throw error
    }
};


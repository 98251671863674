import { create } from "zustand";
import { createBlog, deleteBlog, getAllBlogs, getBlogDetails, updateBlog } from "../Services/blogService";
import { notificationType, showNotification } from "../utils/notification";

const blogStore = create((set, get) => ({
  blogs: [],
  loading: false,
  creating: false,
  totalBlog: 0,
  blogDetail: {},
  fetchAllBlog: async (params) => {
    try {
      set(() => ({
        loading: true
      }));
      const response = await getAllBlogs(params);
      set((state) => ({
        blogs: response.data?.results,
        totalBlog: response.data?.count,
        loading: false,
      }));
    } catch (err) {
      set(() => ({
        loading: false
      }));
      console.error("Error fetching Blog list:", err);
    }
  },
  createBlog: async (data) => {
    try {
      set(() => ({
        creating: true
      }));
      const response = await createBlog(data);
      await get().fetchAllBlog();
      set(() => ({
        creating: false
      }));
      showNotification({
        type: notificationType.SUCCESS,
        message: "Blog added!",
      });
    } catch (error) {
      set(() => ({
        creating: false
      }));
      showNotification({
        type: notificationType.DANGER,
        message: "Blog Create Failed!",
      });
      console.log("Error while creating Blog:", error);
    }
  },
  updateBlog: async (id, data) => {
    try {
      await updateBlog(id, data);
      await get().fetchAllBlog();
      showNotification({
        type: notificationType.SUCCESS,
        message: "Blog update Successful!",
      });
    } catch (error) {
      showNotification({
        type: notificationType.DANGER,
        message: "Blog update Failed!",
      });
      console.error("update Blog", error);
    }
  },
  getBlogDetails: async (id) => {
    try {
      set(() => ({
        loading: true
      }));
      let res = await getBlogDetails(id);
      set(() => ({
        blogDetail: res?.data
      }));
      set(() => ({
        loading: false
      }));
    } catch (error) {
      set(() => ({
        loading: false
      }));
      console.error("Blog details", error);
    }
  },
  deleteBlog: async (id) => {
    try {
      await deleteBlog(id);
      await get().fetchAllBlog();
    } catch (err) {
      console.error("Error while deleting Blog:", err);
    }
  },
}));

export default blogStore;

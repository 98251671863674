import { Search } from "react-feather";
import { Input } from "reactstrap";

const SearchInput = ({ onSearch }) => {
    const handleSearch = (e) => {
        let value = e.target.value;
        if (onSearch) onSearch(value);
    };
    return (
        <div className="job-filter">
            <div className="faq-form" style={{width:"220px"}}>
                <Input onChange={handleSearch} type="text" placeholder="Search.." />
                <Search className="search-icon" />
            </div>
        </div>
    );
};

export default SearchInput;

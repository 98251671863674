export const iconOptions = [
    { value: 'home', label: 'Home' },
    { value: 'bathtub', label: 'Bathtub' },
    { value: 'toothbrush', label: 'Toothbrush' },
    { value: 'car', label: 'Car' },
    { value: 'clock', label: 'Clock' },
    { value: 'camera', label: 'Camera' },
    { value: 'cog', label: 'Setting' },
    { value: 'credit-card', label: 'Credit Card' },
    { value: 'bubbles', label: 'Chat' },
    { value: 'folder', label: 'Folder' },
    { value: 'book', label: 'Book' },
    { value: 'couch', label: 'Couch' },
    { value: 'laptop', label: 'Laptop' },
    { value: 'mic', label: 'Mic' },
    { value: 'phone', label: 'Phone' },
    { value: 'pencil', label: 'Pencil' },
    { value: 'music-note', label: 'Music Note' },
    { value: 'gift', label: 'Gift' },
    { value: 'umbrella', label: 'Umbrella' },
    { value: 'star', label: 'Star' },
    { value: 'tree', label: 'Tree' },
    { value: 'sun', label: 'Sun' },
    { value: 'moon', label: 'Moon' },
    { value: 'fire', label: 'Fire' },
    { value: 'heart', label: 'Heart' },
    { value: 'smile', label: 'Smile' },
    { value: 'sad', label: 'Sad' },
    { value: 'thumbs-up', label: 'Thumbs Up' },
    { value: 'thumbs-down', label: 'Thumbs Down' },
    { value: 'check', label: 'Check' },
    { value: 'cross', label: 'Cross' },
    { value: 'question', label: 'Question' },
    { value: 'exclamation', label: 'Exclamation' },
    { value: 'lock', label: 'Lock' },
    { value: 'unlock', label: 'Unlock' },
    { value: 'shield', label: 'Shield' },
    { value: 'trash', label: 'Trash' },
    { value: 'cloud', label: 'Cloud' },
    { value: 'rain', label: 'Rain' },
    { value: 'snow', label: 'Snow' },
    { value: 'wind', label: 'Wind' },
    { value: 'anchor', label: 'Anchor' },
    { value: 'compass', label: 'Compass' },
    { value: 'map', label: 'Map' },
    { value: 'flag', label: 'Flag' },
    { value: 'key', label: 'Key' },
    { value: 'rocket', label: 'Rocket' },
    { value: 'carrot', label: 'Carrot' },
    { value: 'cherry', label: 'Cherry' },
    { value: 'egg', label: 'Egg' },
    { value: 'fish', label: 'Fish' },
    { value: 'cheese', label: 'Cheese' },
    { value: 'bread', label: 'Bread' },
    { value: 'cake', label: 'Cake' },
    { value: 'ice-cream', label: 'Ice Cream' },
    { value: 'candy', label: 'Candy' },
    { value: 'hotdog', label: 'Hot Dog' },
    { value: 'pizza', label: 'Pizza' },
    { value: 'hamburger', label: 'Hamburger' },
    { value: 'glass', label: 'Glass' },
    { value: 'knife', label: 'Knife' },
    { value: 'chair', label: 'Chair' },
    { value: 'bed', label: 'Bed' },
    { value: 'lamp', label: 'Lamp' },
    { value: 'fan', label: 'Fan' },
    { value: 'window', label: 'Window' },
    { value: 'fence', label: 'Fence' },
    { value: 'road', label: 'Road' },
    { value: 'city', label: 'City' },
    { value: 'leaf', label: 'Leaf' },
    { value: 'tree', label: 'Tree' },
    { value: 'fire', label: 'Fire' },
    { value: 'earth', label: 'Earth' },
    { value: 'star', label: 'Star' },
    { value: 'moon', label: 'Moon' },
    { value: 'sun', label: 'Sun' },
    { value: 'planet', label: 'Planet' },
];

export const siteFeatureIcons = [
    { value: 'rocket', label: 'Rocket' },
    { value: 'cog', label: 'Setting' },
    { value: 'credit-card', label: 'Credit Card' },
    { value: 'bubbles', label: 'Chat' },
    { value: 'gift', label: 'Gift' },
]

import { useEffect, useState } from "react";
import { useStore } from "zustand";
import categoryStore from "../../../store/categoryStore";

const useCategory = () => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { fetchAllCategory, totalCategory, categories } =
    useStore(categoryStore);

  useEffect(() => {
    (async () => {
      try {
        await fetchAllCategory({
          page: currentPage,
          count: pageSize,
          keyword: searchKeyword,
        });
      } catch (error) {
        console.log(error);
      }
    })();
  }, [currentPage, pageSize, searchKeyword]);

  function handlePageChange(page) {
    setCurrentPage(page);
  }

  function handlePageSizeChange(pageSize) {
    setPageSize(pageSize);
  }

  function handleSearch(value) {
    setSearchKeyword(value);
  }
  return {
    data: categories,
    totalRows: totalCategory,
    handlePageChange,
    handlePageSizeChange,
    handleSearch,
  };
};

export default useCategory;

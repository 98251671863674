import apiEndpoints from "./apiEndpoints";
import apiServices from "./apiServices"

export const getAllShippingMethods = async (params) => {
    try {
        const queryString = new URLSearchParams(params).toString();
        const response = await apiServices.get(`${apiEndpoints.getShippingMethods}?${queryString}`);
        return response.data
    } catch (err) {
        console.error("Fetching error in payment method list:", err);
    }
}

export const shippingMethodToggleStatus = async (id) => {
    try {
        const response = await apiServices.post(apiEndpoints.updateShippingMethodStatus + id);
        return response.data
    } catch (err) {
        console.error("failed toggle status", err)
    }
}

export const createShippingMethod = async (data) => {
    try {
        const response = await apiServices.post(apiEndpoints.createShippingMethod, data)
        return response.data
    }
    catch (err) {
        console.log("error in create Payment Method:", err)
        throw err
    }
}

export const updateShippingMethod = async (param, data) => {
    try {
        const response = await apiServices.post(apiEndpoints.updateShippingMethod + param, data)
        return response.data
    } catch (err) {
        console.error("Error while editing Payment Method type:", err);
        throw err
    }
}

export const deleteShippingMethod = async (param) => {
    try {
        const response = await apiServices.delete(apiEndpoints.deleteShippingMethod + param);
        return response;
    } catch (err) {
        console.error("Error while delete a payment Method:", err);
        throw err
    }
}
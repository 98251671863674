import { Container, Row, Col, Card, CardBody } from "reactstrap";
import HeaderCard from "../../Components/Common/Component/HeaderCard";
import Table from "../../CommonElements/Table/Table";
import { tableColumns } from "./components/columns";
import { Breadcrumbs } from "../../AbstractElements";
import { Fragment } from "react";
import TableHeader from "../../CommonElements/TableHeader/TableHeader";
import CCardHeader from "../../CommonElements/CardHeader/CardHeader";
import CAsyncSelect from "../../CommonElements/CommonSelect/AsyncSelect";
import { getCommonParams } from "../../utils/helper";
import { getAllBusinessList } from "../../Services/businessService";
import useOrders from "./hooks/useOrders";
import CSelect from "../../CommonElements/CommonSelect/Select";

const OrdersPage = () => {
  const {
    data,
    totalRows,
    handlePageChange,
    handlePageSizeChange,
    handleSearch,
    selectOrderStatus,
  } = useOrders();
  return (
    <Fragment>
      <Breadcrumbs parent="Home" title="Orders" mainTitle="Orders" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CCardHeader
                title="Orders"
                rightContent={
                  <div>
                    <p>Filter By Status :</p>
                    <CSelect
                      name="status"
                      options={[
                        {
                          label:"All",
                          value:"all"
                        },
                        {
                          label:"Pending",
                          value:"Pending"
                        },
                        {
                          label:"Shipped",
                          value:"Shipped"
                        },
                        {
                          label:"Delivered",
                          value:"Delivered"
                        },
                        {
                          label:"Cancelled",
                          value:"Cancelled"
                        },
                      ]}
                      handleSelectChange={selectOrderStatus}
                    />
                  </div>
                }
              />
              <TableHeader onSearch={handleSearch} />
              <CardBody>
                <Table
                  data={data}
                  columns={tableColumns}
                  onPageChange={handlePageChange}
                  onPageSizeChange={handlePageSizeChange}
                  totalRows={totalRows}
                  pageSize={10}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default OrdersPage;

import { Container, Row, Col, Card, CardBody } from "reactstrap";
import HeaderCard from "../../Components/Common/Component/HeaderCard";
import Table from "../../CommonElements/Table/Table";
import { tableColumns } from "./components/columns";
import { Breadcrumbs } from "../../AbstractElements";
import { Fragment } from "react";
import TableHeader from "../../CommonElements/TableHeader/TableHeader";
import CCardHeader from "../../CommonElements/CardHeader/CardHeader";
import { getCommonParams } from "../../utils/helper";
import { getAllBusinessList } from "../../Services/businessService";
import useShippingMethods from "./hooks/useShippingMethods";
import AddShippingMethod from "./components/add-shipping-method";

const ShippingMethodsPage = () => {
  const {
    data,
    totalRows,
    handlePageChange,
    handlePageSizeChange,
    handleSearch,
  } = useShippingMethods();
  return (
    <Fragment>
      <Breadcrumbs parent="Home" title="Home" mainTitle="Shipping Methods" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CCardHeader title="Shipping Methods" rightContent={<AddShippingMethod />} />
              <TableHeader onSearch={handleSearch} />
              <CardBody>
                <Table
                  data={data}
                  columns={tableColumns}
                  onPageChange={handlePageChange}
                  onPageSizeChange={handlePageSizeChange}
                  totalRows={totalRows}
                  pageSize={10}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ShippingMethodsPage;

import { Controller } from "react-hook-form";
import { useRef } from "react";
import { select_styles } from "./select-style";
import Select from "react-select";

const CSelect = ({
    name,
    control,
    placeholder = "Search...",
    options,
    components,
    handleSelectChange,
    formatOptionLabel,
    isMulti = false,
    ...rest
}) => {

    return control ?
        (
            <Controller
                name={name}
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <Select
                        {...field}
                        isSearchable
                        menuPosition="fixed"
                        menuPortalTarget={document.body}
                        formatOptionLabel={formatOptionLabel}
                        options={options}
                        className="react-select"
                        classNamePrefix="select"
                        classNames={{
                            menuPortal: () => "pointer-events-auto",
                            option: (state) =>
                                state.isSelected
                                    ? "bg-secondary text-white"
                                    : state.isFocused
                                        ? "bg-secondary text-white"
                                        : "",
                            menu: () => "bg-primary",
                            control: (state) =>
                                state.isFocused
                                    ? "border-primary shadow-none"
                                    : error
                                        ? "border-destructive"
                                        : "",
                        }}
                        styles={select_styles}
                        components={components}
                        placeholder={placeholder}
                        isMulti={isMulti}
                        onChange={(option) => {
                            field.onChange(option);
                            handleSelectChange && handleSelectChange(option);
                        }}
                        {...rest}
                    />
                )}
            />
        )
        :
        <Select
            isSearchable
            menuPosition="fixed"
            menuPortalTarget={document.body}
            options={options}
            formatOptionLabel={formatOptionLabel}
            className="react-select"
            classNamePrefix="select"
            classNames={{
                menuPortal: () => "pointer-events-auto",
                option: (state) =>
                    state.isSelected
                        ? "bg-secondary text-white"
                        : state.isFocused
                            ? "bg-secondary text-white"
                            : "",
                menu: () => "bg-primary",
                control: (state) =>
                    state.isFocused
                        ? "border-primary shadow-none"
                        : "",
            }}
            styles={select_styles}
            components={components}
            placeholder={placeholder}
            isMulti={isMulti}
            onChange={(option) => {
                handleSelectChange && handleSelectChange(option);
            }}
            {...rest}
        />
};

export default CSelect;

import apiEndpoints from "./apiEndpoints";
import apiServices from "./apiServices";

export const getAllProductList = async (params) => {
  try {
    const queryString = new URLSearchParams(params).toString();
    const response = await apiServices.get(
      `${apiEndpoints.getAllProduct}?${queryString}`
    );
    return response.data;
  } catch (err) {
    console.error("Fetching error in business list:", err);
    throw err;
  }
};

export const getSingleProductInfo = async (param) => {
  try {
    const response = await apiServices.get(
      apiEndpoints.getSingleProductInfo + param
    );
    return response.data;
  } catch (err) {
    console.error("error while fetching business Simple:", err);
    throw err;
  }
};

export const productToggleStatus = async (id) => {
  try {
    const response = await apiServices.get(
      apiEndpoints.ProductStatusToggle + id
    );
    return response.data;
  } catch (err) {
    console.error("failed toggle status", err);
  }
};

export const advanceToggleStatus = async (id) => {
  try {
    const response = await apiServices.get(
      apiEndpoints.productAdvancePayToggle + id
    );
    return response.data;
  } catch (err) {
    console.error("failed toggle commission", err);
  }
};

export const getProductByBusiness = async (param) => {
  try {
    const response = await apiServices.get(
      apiEndpoints.getProductByBusiness + param
    );
    return response.data;
  } catch (err) {
    console.error("error while get product form business", err);
    throw err;
  }
};

export default {
  getAllProductList,
  getSingleProductInfo,
  productToggleStatus,
  advanceToggleStatus,
  getProductByBusiness,
};

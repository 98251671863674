import DeleteBusinessType from "./delete-business-type";
import EditBusinessType from "./edit-business-type";

export const tableColumns = [
  {
    name: "Business Type",
    selector: (row) => row,
    sortable: true,
    cell: (row) => <span className="f-w-500">{row?.business_type}</span>,
  },
  {
    name: "Actions",
    center:true,
    cell: (row) => {
      return (
        <div className="d-flex" style={{ gap: "6px",}}>
          <EditBusinessType businessType={row} />
          <DeleteBusinessType businessType={row} />
        </div>
      );
    },
  },
];

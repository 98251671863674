import { getCartTotal } from '../../../../Services/Ecommerce.service';
import { Price, ProductName, Quantity, Sub_total } from '../../../../Constant';
import { H6, P } from '../../../../AbstractElements';
import InvoiceHeader from './InvoiceHeader';
import InvoiceDescription from './InvoiceDescription';
import PrintInvoice from './PrintInvoice';
import { Col, Label, Row, Table, Container, CardBody, Card } from 'reactstrap';
import React, { Component, Fragment } from 'react';

class ItemDescription extends Component {
  render() {
    const { cart, symbol, data } = this.props;
    const orderList = data?.order_data?.order_cart || []; // Ensure orderList is an array even if data is not yet available

    return (
      <Fragment>
        <Container>
          <Row>
            <Col sm='12'>
              <Card>
                <CardBody>
                  <div className='invoice'>
                    <div>
                      <InvoiceHeader data={data} />
                      <InvoiceDescription data={data} />

                      <div className='table-responsive invoice-table' id='table'>
                        <Table bordered striped>
                          <thead>
                            <tr>
                            <th className='item'>
                                <H6 attrH6={{ className: 'p-2 mb-0' }}>{"Product Code"}</H6>
                              </th>
                              <th className='item'>
                                <H6 attrH6={{ className: 'p-2 mb-0' }}>{ProductName}</H6>
                              </th>
                              <th className='quantity'>
                                <H6 attrH6={{ className: 'p-2 mb-0' }}>{Quantity}</H6>
                              </th>
                              <th className='Rate'>
                                <H6 attrH6={{ className: 'p-2 mb-0' }}>{Price}</H6>
                              </th>
                              <th className='subtotal'>
                                <H6 attrH6={{ className: 'p-2 mb-0' }}>{Sub_total}</H6>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {orderList.length > 0 ? (
                              orderList.map((item) => (
                                
                                <tr key={item.id}>
                                  <td>
                                    <Label>{"PID"+item.id}</Label>
                                  </td>
                                  <td>
                                    <Label>{item.name}</Label>
                                  </td>
                                  <td>
                                    <P attrPara={{ className: 'itemtext digits' }}>{item.quantity}</P>
                                  </td>
                                  <td>
                                    <P attrPara={{ className: 'itemtext digits' }}>
                                      {symbol} {item.unit_price}
                                    </P>
                                  </td>
                                  <td className='payment digits'>
                                    <P attrPara={{ className: 'itemtext digits' }}>
                                      {symbol} {item.total_amount}
                                    </P>
                                  </td>
                                </tr>
                                
                              ))
                            ) : (
                              <tr>
                                <td colSpan="4">No items found</td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                      <PrintInvoice />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default ItemDescription;

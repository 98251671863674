import { create } from 'zustand';
import adService from '../Services/adService';


const addStore = create((set, get) => ({
  addOneList: [],
  addTwoList: [],
  addThreeList: [],
  fetchAddOne: async () => {
    try {
      const response = await adService.getAllAd1();
      set({ addOneList: response?.data });
    } catch (err) {
      console.log(err)
    }
  },
  updateAddOne: async (id, data) => {
    try {
      const updatedData = await adService.updateAdSection1(id, data);
      await get().fetchAddOne();
    } catch (err) {
      console.log(err)
    }
  },
  fetchAddTwo: async () => {
    try {
      const response = await adService.getAllAd2();
      set({ addTwoList: response?.data });
    } catch (err) {
      console.log(err)
    }
  },
  updateAddTwo: async (id, data) => {
    try {
      const updatedData = await adService.updateAdSection2(id, data);
      await get().fetchAddTwo();
    } catch (err) {
      console.log(err)
    }
  },
  fetchAddThree: async () => {
    try {
      const response = await adService.getAllAd3();
      set({ addThreeList: response?.data });
    } catch (err) {
      console.log(err)
    }
  },
  updateAddThree: async (id, data) => {
    try {
      const updatedData = await adService.updateAdSection3(id, data);
      await get().fetchAddThree();
    } catch (err) {
      console.log(err)
    }
  },
}));

export default addStore;

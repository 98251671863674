import { Container, Row, Col, Card, CardBody } from "reactstrap";
import HeaderCard from "../../Components/Common/Component/HeaderCard";
import { useStore } from "zustand";
import businessStore from "../../store/businessStore";
import Table from "../../CommonElements/Table/Table";
import { tableColumns } from "./components/columns";
import { Breadcrumbs } from "../../AbstractElements";
import useBusiness from "./hooks/useCustomers";
import TableHeader from "../../CommonElements/TableHeader/TableHeader";
import useCustomers from "./hooks/useCustomers";
import { Fragment } from "react";

const CustomersPage = () => {
  const {
    data,
    loading,
    totalRows,
    handlePageChange,
    handlePageSizeChange,
    handleSearch,
  } = useCustomers();
  return (
    <Fragment>
      <Breadcrumbs
        parent="Table"
        title="All Customers"
        mainTitle="All Customers"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard title="All Customers" />
              <TableHeader onSearch={handleSearch} />
              <CardBody>
                <Table
                  data={data}
                  loading={loading}
                  columns={tableColumns}
                  onPageChange={handlePageChange}
                  onPageSizeChange={handlePageSizeChange}
                  totalRows={totalRows}
                  pageSize={10}
                />
                {/* <DataTableComponent/> */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default CustomersPage;

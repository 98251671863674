import { create } from "zustand";
import { createShippingMethod, deleteShippingMethod, getAllShippingMethods, shippingMethodToggleStatus, updateShippingMethod } from "../Services/shippingMethodService";

const shippingMethodStore = create((set, get) => ({
  shippingMethods: [],
  totalShippingMethod: 0,
  fetchAllMethods: async (params) => {
    try {
      const response = await getAllShippingMethods(params);
      set({
        shippingMethods: response.data?.results,
        totalShippingMethod: response.data?.count
      });
    } catch (err) {
      console.error("Error fetching payment method list:", err);
    }
  },

  createShippingMethod: async (data) => {
    try {
      const response = await createShippingMethod(data);
      await get().fetchAllMethods()
    } catch (error) {
      console.log("Error while creating payment method:", error);
    }
  },

  updateShippingMethod: async (id, data) => {
    try {
      const response = await updateShippingMethod(id, data);
      await get().fetchAllMethods()
    } catch (err) {
      console.error("Error while editing payment method:", err);
    }
  },

  deleteShippingMethod: async (id) => {
    try {
      await deleteShippingMethod(id);
      await get().fetchAllMethods()
    } catch (err) {
      console.error("Error while deleting payment method:", err);
    }
  },

  toggleShippingMethodStatus: async (id) => {
    try {
      await shippingMethodToggleStatus(id);
    } catch (err) {
      console.error("Error while updating payment method status:", err);
    }
  },

}));

export default shippingMethodStore;

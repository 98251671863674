import { useEffect, useState } from "react";
import { useStore } from "zustand";
import blogStore from "../../../store/blogStore";

const useBlogs = () => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { fetchAllBlog, totalBlog, blogs } =
    useStore(blogStore);

  useEffect(() => {
    (async () => {
      try {
        await fetchAllBlog({
          page: currentPage,
          count: pageSize,
          keyword: searchKeyword,
        });
      } catch (error) {
        console.log(error);
      }
    })();
  }, [currentPage, pageSize, searchKeyword]);

  function handlePageChange(page) {
    setCurrentPage(page);
  }

  function handlePageSizeChange(pageSize) {
    setPageSize(pageSize);
  }

  function handleSearch(value) {
    setSearchKeyword(value);
  }
  return {
    data: blogs,
    totalRows: totalBlog,
    handlePageChange,
    handlePageSizeChange,
    handleSearch,
  };
};

export default useBlogs;

import React, { Fragment, useContext, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { H5, H4, H6, P, Image, Btn } from '../../AbstractElements';
import { Link } from 'react-router-dom';
import { MyProfile, Bio, Password, Website, Save, EmailAddress } from '../../Constant';
import useUser from './hooks/useUser';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
const profileSchema = z.object({
    first_name: z.string().min(1, "First Name is required"),
    last_name: z.string().min(1, "Last Name is required"),
    new_password: z.string().nullable(),
});
const ProfilePage = () => {
    const { user, loading, creating, updating, updateUserInfo } = useUser();
    const [togglePassword, setTogglePassword] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        control,
    } = useForm({
        mode: "all",
        resolver: zodResolver(profileSchema),
        values: {
            first_name: user?.first_name,
            last_name: user?.last_name,
            new_password: user?.password,
        },
    });
    const onSubmit = (data) => {
        updateUserInfo(data);
    };
    return (
        <Fragment>
            <Card>
                <CardHeader>
                    <H4 attrH4={{ className: 'card-title mb-0' }}>{MyProfile}</H4>
                </CardHeader>
                <CardBody>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row className='mb-2'>
                            <div className='profile-title'>
                                <div className='media'>
                                    <div className='media-body'>
                                        <H5 attrH5={{ className: 'mb-1' }}>Hi! {user?.first_name} {user?.last_name}</H5>
                                    </div>
                                </div>
                            </div>
                        </Row>
                        <FormGroup className='mb-3'>
                            <Label className='form-label'>First Name</Label>
                            <input
                                className="form-control"
                                type="text"
                                {...register("first_name")}
                            />
                            {errors.first_name && (
                                <span style={{ color: "red" }}>{errors.first_name.message}</span>
                            )}
                        </FormGroup>
                        <FormGroup className='mb-3'>
                            <Label className='form-label'>Last Name</Label>
                            <input
                                className="form-control"
                                type="text"
                                {...register("last_name")}
                            />
                            {errors.last_name && (
                                <span style={{ color: "red" }}>{errors.last_name.message}</span>
                            )}
                        </FormGroup>
                        <FormGroup className='position-relative'>
                            <Label className='col-form-label m-0'>Password</Label>
                            <div className='position-relative'>
                                <input
                                    className='form-control'
                                    type={togglePassword ? 'text' : 'password'}
                                    placeholder='*********'
                                    {...register("new_password")}
                                />
                                <div className='show-hide' onClick={() => setTogglePassword(!togglePassword)}>
                                    <span className={togglePassword ? '' : 'show'}></span>
                                </div>
                            </div>
                            {errors.new_password && (
                                <span style={{ color: "red" }}>{errors.new_password.message}</span>
                            )}
                        </FormGroup>
                        <FormGroup className='mb-3'>
                            <Label className='form-label'>{EmailAddress}</Label>
                            <Input disabled className='form-control' value={user?.email} />
                        </FormGroup>
                        <div className='form-footer'>
                            <Button type="submit" color="primary me-3">
                                {
                                    updating ?
                                        "Saving..."
                                        :
                                        "Save"
                                }
                            </Button>
                        </div>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    );
};
export default ProfilePage;

// //User app
import UsersCardssContain from "../Components/Application/Users/UsersCards";
import UsersEditContain from "../Components/Application/Users/UsersEdit";
import UsersProfileContain from "../Components/Application/Users/UsersProfile";

//Seller Order
import WindowSellerOrderList from "../Components/Application/SellerOrder";
import SellerOrderInvoice from "../Components/Application/SellerOrder/SellerOrderInvoice";
import MainOrderInvoice from "../Components/Application/SellerOrder/SellerOrderInvoice/MainOrderInvoice";
// //E-commerce
import Invoice from "../Components/Application/Ecommerce/Invoice";
import PricingMemberShip from "../Components/Application/Ecommerce/PricingMemberShip";

//business type
import SellerFinance from "../Components/Application/seller finance";

import WindowShippingWithDraw from "../Components/Application/ShippingWithdraw";

//Home ads
import Dashboard from "../pages/dashboard/DashboardPage";
import BusinessOnboardListPage from "../pages/BusinessOnboardList/BusinessOnboardListPage";
import CustomersPage from "../pages/customers/CustomersPage";
import CouponsPage from "../pages/CouponsPage/CouponsPage";
import DealsOfTheDay from "../pages/deals-of-the-day/DealsOfTheDay";
import ProductPage from "../pages/product-page/ProductPage";
import CategoryPage from "../pages/category-page/CategoryPage";
import HomeSliderPage from "../pages/home-slider/HomeSliderPage";
import HomeAddsPage from "../pages/home-adds/HomeAdds";
import BusinessTypePage from "../pages/business-type/BusinessTypePage";
import OrdersPage from "../pages/orders-page/OrdersPage";
import SellerOrdersPage from "../pages/seller-orders-page/SellerOrdersPage";
import PaymentMethodsPage from "../pages/payment-methods/PaymentMethodsPage";
import ShippingMethodsPage from "../pages/shipping-methods/ShippingMethodsPage";
import SiteInfoPage from "../pages/site-info-page/SiteInfoPage";
import WithdrawlPage from "../pages/withdraw-page/WithdrawlPage";
import SiteFeaturesPage from "../pages/site-features-page/SiteFeaturesPage";
import PgLogPage from "../pages/pg-log-page/PgLogPage";
import PgHistoryPage from "../pages/pg-history-page/PgHistoryPage";
import BlogPage from "../pages/blog-page/BlogPage";
import CreateBlog from "../pages/create-blog-page/create-blog";
import ProfilePage from "../pages/profile-page/ProfilePage";
import BlogDetailsPage from "../pages/blog-details-page/BlogDetailsPage";

export const routes = [
  { path: `/dashboard/default/`, Component: <Dashboard /> },
  { path: `/app/customer`, Component: <CustomersPage /> },
  { path: `/app/business`, Component: <BusinessOnboardListPage /> },
  { path: `/app/coupon/coupon-list/`, Component: <CouponsPage /> },
  { path: `/app/deal-of-the-day/deals-product/`, Component: <DealsOfTheDay /> },
  { path: `/app/seller-finance`, Component: <SellerFinance /> },
  { path: `/app/payment-methods`, Component: <PaymentMethodsPage /> },
  { path: `/app/shipping-methods`, Component: <ShippingMethodsPage /> },
  { path: `/app/all-orders/`, Component: <OrdersPage /> },
  { path: `/app/seller-orders/`, Component: <SellerOrdersPage /> },
  { path: `/app/seller-order-list/:id`, Component: <SellerOrderInvoice /> },
  { path: `/app/withdrawal`, Component: <WithdrawlPage /> },
  { path: `/app/shipping-withdraw`, Component: <WindowShippingWithDraw /> },
  { path: `/app/payment-gateway/logs`, Component: <PgLogPage /> },
  { path: `/app/payment-gateway/history`, Component: <PgHistoryPage /> },
  { path: `/app/ecommerce/product/`, Component: <ProductPage /> },
  { path: `/app/ecommerce/category/`, Component: <CategoryPage /> },
  { path: `/app/ecommerce/slider/`, Component: <HomeSliderPage /> },
  { path: `/app/home-ads`, Component: <HomeAddsPage /> },
  { path: `/app/ecommerce/business-type/`, Component: <BusinessTypePage /> },
  { path: `/app/site-info/`, Component: <SiteInfoPage /> },
  { path: `/app/site-features/`, Component: <SiteFeaturesPage /> },
  { path: `/app/blogs/`, Component: <BlogPage /> },
  { path: `/app/blogs/:id`, Component: <BlogDetailsPage/> },
  { path: `/app/create-blog/`, Component: <CreateBlog /> },
  { path: `/app/users/profile/`, Component: <ProfilePage /> },
];

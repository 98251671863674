import { Container, Row, Col, Card, CardBody } from "reactstrap";
import HeaderCard from "../../Components/Common/Component/HeaderCard";
import Table from "../../CommonElements/Table/Table";
import { tableColumns } from "./components/columns";
import { Breadcrumbs } from "../../AbstractElements";
import { Fragment } from "react";
import TableHeader from "../../CommonElements/TableHeader/TableHeader";
import CCardHeader from "../../CommonElements/CardHeader/CardHeader";
import { getCommonParams } from "../../utils/helper";
import { getAllBusinessList } from "../../Services/businessService";
import useSlider from "./hooks/useSlider";
import AddSlide from "./components/add-slide";

const HomeSliderPage = () => {
  const {
    data,
    totalRows,
    handlePageChange,
    handlePageSizeChange,
    handleSearch,
  } = useSlider();
  return (
    <Fragment>
      <Breadcrumbs parent="Home" title="Slide" mainTitle="Slide" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CCardHeader title="Home Slides" rightContent={<AddSlide />} />
              <TableHeader onSearch={handleSearch} />
              <CardBody>
                <Table
                  data={data}
                  columns={tableColumns}
                  onPageChange={handlePageChange}
                  onPageSizeChange={handlePageSizeChange}
                  totalRows={totalRows}
                  pageSize={10}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default HomeSliderPage;

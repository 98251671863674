import apiEndpoints from "./apiEndpoints";
import apiServices from "./apiServices"

export const getSellerFinanceById = async (id, size, page) => {
    try {
        const response = await apiServices.get(`${apiEndpoints.getShopFinanceById}${id ? "/"+id:''}?count=${size}&page=${page}`);
        return response.data;
    } catch (err) {
        console.error("error while get seller finance ID", err)
        throw err
    }
}


export default { getSellerFinanceById }
import { Media } from "reactstrap";
import { Image } from "../../../AbstractElements";

export const tableColumns = [
    {
        name: "Name",
        selector: (row) => row.name,
        sortable: true,
        center: true,
        cell: (row) => {
            const initials = row?.name.slice(0, 2).toUpperCase();
            return row.photo_url ?
                <Media className="d-flex">
                    <Image
                        attrImage={{
                            className: "rounded-circle img-50 me-3",
                            src: row.photo_url,
                            alt: "customer-photo",
                        }}
                    />
                    <Media body className="align-self-center">
                        <div>{row.name}</div>
                    </Media>
                </Media>
                :
                <>
                    <div
                        className="user-initials me-3"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#cd8b62',
                            color: '#333',
                            width: '50px',
                            height: '50px',
                            borderRadius: '50%',
                            fontWeight: 'bold',
                            fontSize: '18px',
                        }}
                    >
                        {initials}
                    </div>
                    <div>{row.name}</div>
                </>

        }
    },
    {
        name: "Email",
        selector: (row) => row.email_address,
        sortable: true,
        center: true,
        cell: (row) => (
            <span className="f-w-700 font-success">{row.email_address}</span>
        ),
    },
    {
        name: "Mobile Number",
        selector: (row) => row.mobile_number,
        sortable: true,
        center: true,
        cell: (row) => (
            <span className="badge badge-light-primary">{row.mobile_number}</span>
        ),
    },
];

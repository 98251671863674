import React, { useState } from "react";
import { Controller } from "react-hook-form";
import Files from "react-files";
import { UploadCloud } from "react-feather";
import Btn from "../Button";
import "./upload-image.css";

const UploadImage = ({ control, name }) => {
  const [files, setFiles] = useState([]);

  // Convert file to Base64
  const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const deleteFile = (onChange) => {
    setFiles([]);
    onChange("");
  };

  const onFilesChange = async (newFiles, onChange) => {
    setFiles(newFiles);

    // Convert to Base64 and pass to react-hook-form onChange
    if (newFiles.length > 0) {
      const base64File = await toBase64(newFiles[0]);
      onChange(base64File); // Pass Base64 value to form
    }
  };

  const onFilesError = (error) => {
    console.error("File error:", error);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <div>
          <Files
            className="files-dropzone"
            onChange={(newFiles) => onFilesChange(newFiles, onChange)}
            onError={onFilesError}
            accepts={["image/*"]}
            multiple={false}
            maxFileSize={10000000}
            minFileSize={0}
            clickable
          >
            <div className="dropzone-area">
              {value ? ( // Check if value is already present
                <div className="uploaded-image">
                  <img
                    className="image-preview"
                    src={
                      typeof value === "string" && value.startsWith("data:")
                        ? value
                        : value
                    }
                    alt="Uploaded"
                  />
                </div>
              ) : files.length > 0 ? (
                <div className="uploaded-image">
                  <img
                    className="image-preview"
                    src={files[0]?.preview?.url}
                    alt="Uploaded"
                  />
                </div>
              ) : (
                <div className="dropzone-content">
                  <UploadCloud size={48} className="upload-icon" />
                  <p>Drag & drop an image here, or click to select one</p>
                </div>
              )}
            </div>
          </Files>
          {(files.length > 0 || value) && (
            <div className="d-flex justify-content-center">
              <Btn
                attrBtn={{
                  className: "mt-3 delete-btn",
                  color: "danger",
                  type: "button",
                  onClick: () => deleteFile(onChange), // Clear form and preview when deleted
                }}
              >
                Delete Image
              </Btn>
            </div>
          )}
        </div>
      )}
    />
  );
};

export default UploadImage;

import { useEffect, useState } from "react";
import { useStore } from "zustand";
import orderStore from "../../../store/orderStore";

const useOrders = () => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [orderStatus, setOrderStatus] = useState({ value: "all", label: "All" });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { fetchAllOrder, orders, totalOrders } = useStore(orderStore);

  useEffect(() => {
    (async () => {
      try {
        await fetchAllOrder(orderStatus.value == "all" ? "" : orderStatus?.value, {
          page: currentPage,
          count: pageSize,
          keyword: searchKeyword,
        });
      } catch (error) {
        console.log(error);
      }
    })();
  }, [currentPage, pageSize, searchKeyword, orderStatus]);

  function handlePageChange(page) {
    setCurrentPage(page);
  }

  function handlePageSizeChange(pageSize) {
    setPageSize(pageSize);
  }

  function handleSearch(value) {
    setSearchKeyword(value);
  }
  function selectOrderStatus(value) {
    setOrderStatus(value);
  }
  return {
    data: orders,
    totalRows: totalOrders,
    handlePageChange,
    handlePageSizeChange,
    handleSearch,
    selectOrderStatus,
  };
};

export default useOrders;

import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge, Alert } from 'reactstrap';
import shippingWithdrawStore from '../../../store/sellerShippingWithdrawStore';
import ImageModal from './ImageModal';

const statusOptions = [
  { value: 'Pending', label: 'Pending', color: 'warning' },
  { value: 'Approved', label: 'Approved', color: 'success' },
  { value: 'Rejected', label: 'Rejected', color: 'danger' },
];

const getStatusColor = (status) => {
  const option = statusOptions.find((opt) => opt.value === status);
  return option ? option.color : 'secondary';
};

const OrderHistoryTable = () => {
  const {
    allShippingWithdrawList,
    updateFilterPaginationData,
    totalRows,
    updateRequestStatus,
    successMessage,
    errorMessage,
    updateBusinessIdData,
    fetchAllShippingWithdrawList
  } = shippingWithdrawStore();

  const [currentPage, setCurrentPage] = useState(1);
  const [dataCount, setDataCount] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState('');
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [currentRowId, setCurrentRowId] = useState(null);
  const [currentStatus, setCurrentStatus] = useState('');
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertColor, setAlertColor] = useState('success');

  useEffect(() => {
    if (updateBusinessIdData !== 0) {
      updateFilterPaginationData(currentPage, dataCount);
    }
  }, [updateBusinessIdData, currentPage, dataCount, updateFilterPaginationData]);

  useEffect(() => {
    if (successMessage || errorMessage) {
      setAlertMessage(successMessage || errorMessage);
      setAlertColor(successMessage ? 'success' : 'danger');
      setAlertVisible(true);

      shippingWithdrawStore.setState({ successMessage: '', errorMessage: '' });

      const timer = setTimeout(() => {
        setAlertVisible(false);
        setAlertMessage('');
        setAlertColor('');
      }, 3000);

      return () => clearTimeout(timer); // Clean up the timer

      fetchAllShippingWithdrawList();
    }
  }, [successMessage, errorMessage, fetchAllShippingWithdrawList]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleCountChange = (newPerPage, page) => {
    setDataCount(newPerPage);
    setCurrentPage(page);
    updateFilterPaginationData(page, newPerPage);
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const toggleStatusModal = () => {
    setStatusModalOpen(!statusModalOpen);
  };

  const handleStatusClick = (rowId, currentStatus) => {
    setCurrentRowId(rowId);
    setCurrentStatus(currentStatus);
    toggleStatusModal();
  };

  const handleStatusChange = async (newStatus) => {
    await updateRequestStatus(currentRowId, newStatus);
    toggleStatusModal();
  };

  const updatedData = {
    productRow: allShippingWithdrawList ? allShippingWithdrawList.map((item) => ({
      id: item.id,
      seller: <div className='product-name'>{item?.business}</div>,
      payoutMethod: item?.payout_method,
      payoutDetails: <div><p>{item?.payout_details}</p></div>,
      payoutNotes: <div>{item?.notes}</div>,
      requestAmount: <div className='product-name'>{"৳" + item?.req_amount}</div>,
      currentBalance: "৳" + item?.new_balance,
      requestStatus: (
        <div onClick={() => handleStatusClick(item.id, item.req_status)}>
          <Badge color={getStatusColor(item.req_status)}>{item?.req_status}</Badge>
        </div>
      ),
      requestDate: item?.created_at,
    })) : [],
    productColumns: [
      {
        name: 'Seller',
        selector: (row) => row.seller,
        sortable: true,
        center: true,
        minWidth: '150px',
        maxWidth: '200px',
      },
      {
        name: 'Payout Method',
        selector: (row) => row.payoutMethod,
        sortable: true,
        center: true,
        minWidth: '100px',
        maxWidth: '250px',
      },
      {
        name: 'Payout Details',
        selector: (row) => row.payoutDetails,
        sortable: true,
        center: true,
        minWidth: '200px',
        maxWidth: '400px',
      },
      {
        name: 'Payout Notes',
        selector: (row) => row.payoutNotes,
        sortable: true,
        center: true,
        minWidth: '120px',
        maxWidth: '180px',
      },
      {
        name: 'Request Amount',
        selector: (row) => row.requestAmount,
        sortable: true,
        center: true,
        minWidth: '120px',
        maxWidth: '180px',
      },
      {
        name: 'Current Balance',
        selector: (row) => row.currentBalance,
        sortable: true,
        center: true,
        minWidth: '120px',
        maxWidth: '180px',
      },
      {
        name: 'Request Status',
        selector: (row) => row.requestStatus,
        sortable: true,
        center: true,
        minWidth: '120px',
        maxWidth: '180px',
      },
      {
        name: 'Request Date',
        selector: (row) => row.requestDate,
        sortable: true,
        center: true,
        minWidth: '120px',
        maxWidth: '180px',
      },
    ],
  };

  return (
    <div className='table-responsive table-bordernone orderHistoryTable product-table'>
      <Alert color={alertColor} isOpen={alertVisible} toggle={() => setAlertVisible(false)}>
        {alertMessage}
      </Alert>
      <DataTable
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        columns={updatedData.productColumns}
        data={updatedData.productRow}
        highlightOnHover
        striped
        responsive
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleCountChange}
        customStyles={{
          rows: {
            style: {
              minHeight: '72px',
            },
          },
          headCells: {
            style: {
              backgroundColor: '#f8f9fa',
              fontWeight: 'bold',
            },
          },
          cells: {
            style: {
              padding: '16px',
            },
          },
        }}
      />
      <ImageModal isOpen={modalOpen} toggle={toggleModal} imageUrl={modalImage} />

      <Modal isOpen={statusModalOpen} toggle={toggleStatusModal}>
        <ModalHeader toggle={toggleStatusModal}>Change Request Status</ModalHeader>
        <ModalBody className="d-flex flex-column align-items-center">
          {statusOptions.map((status) => (
            <Button
              key={status.value}
              color={status.color}
              onClick={() => handleStatusChange(status.value)}
              block
              className="mb-2"
            >
              <i className={`fa fa-${status.value.toLowerCase()}`} aria-hidden="true"></i> {status.label}
            </Button>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleStatusModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default OrderHistoryTable;

import React, { useEffect, useState } from "react";

const ToggleBtn = ({ value, onToggle }) => {
  const [isActive, setIsActive] = useState(value);

  useEffect(() => {
    setIsActive(value);
  }, [value]);

  const handleToggle = () => {
    onToggle();
    setIsActive(!isActive);
  };

  return (
    <div className="toggle-switch" onClick={handleToggle}>
      <div className={`switch ${isActive ? 'active' : 'inactive'}`}>
        <div className="switch-handle"></div>
      </div>
      <div className={`mt-1 badge ${isActive ? 'badge-light-success' : 'badge-light-danger'}`}>
        {isActive ? 'Active' : 'Inactive'}
      </div>
    </div >
  );
};

export default ToggleBtn;

import React, { useState } from "react";
import { Edit } from "react-feather";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import RModal from "../../../CommonElements/RModal/RModal";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { getAllCategory } from "../../../Services/productCategoryService";
import { getCommonParams } from "../../../utils/helper";
import CAsyncSelect from "../../../CommonElements/CommonSelect/AsyncSelect";
import CSelect from "../../../CommonElements/CommonSelect/Select";
import categoryStore from "../../../store/categoryStore";
import UploadImage from "../../../CommonElements/UploadImage/upload-image";
import { iconOptions } from "../../../utils/iconOptions";

// Zod schema for validation
const categorySchema = z.object({
  name: z.string().min(1, "Name is required"),
  parent: z
    .object({
      label: z.string(),
      value: z.string().or(z.number()),
    })
    .nullable(),
  icon: z.object({
    value: z.string().min(1, { message: "Please select an Icon." }),
    label: z.string()
  }).nullable().refine(data => data && data.value && data.label, { message: "Icon is required" }),
  image: z.string().min(1, "Image is required"),
});

const EditCategory = ({ category }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const store = categoryStore();
  const Category = category?.parent.name
    ? { value: category?.id, label: category?.name }
    : null;
  const Icon = category?.icon
    ? { value: category?.icon, label: category?.icon }
    : null;
  // Initialize useForm with zod schema resolver
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({
    mode: "all",
    resolver: zodResolver(categorySchema),
    defaultValues: {
      name: category?.name,
      parent: Category,
      icon: Icon,
      image: category?.image,
    },
  });
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    reset();
  };
  const onSubmit = (data) => {
    const categoryParent = data?.parent?.value ? data?.parent?.value : 0;
    const icon = data?.icon?.value;

    const newData = {
      ...data,
      parent: categoryParent,
      icon: icon
    };
    if (data.image.startsWith("http")) {
      delete newData.image;
    }
    store.updateCategory(category?.id, newData);
    toggleModal();
  };

  return (
    <div>
      <Button
        className="p-1"
        style={{ width: "max-content" }}
        color="primary"
        size="sm"
        onClick={toggleModal}
      >
        <Edit />
      </Button>
      <RModal
        isOpen={isModalOpen}
        toggleModal={toggleModal}
        title="Edit Coupon"
        bodyContent={
          <>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <ModalBody>
                <FormGroup>
                  <Label for="name">Category Name:</Label>
                  <input
                    className="form-control"
                    type="text"
                    {...register("name")}
                  />
                  {errors.name && (
                    <span style={{ color: "red" }}>{errors.name.message}</span>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="parent">Parent Category (optional)</Label>
                  <CAsyncSelect
                    name="parent"
                    control={control}
                    fetchOptions={async (value) => {
                      return getAllCategory(getCommonParams(value));
                    }}
                    modifyOptions={(categories) => {
                      return categories.map((category) => ({
                        label: category.name,
                        value: category.id,
                      }));
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="icon">Category Icon:</Label>
                  <CSelect
                    control={control}
                    name="icon"
                    options={iconOptions}
                    formatOptionLabel={({ value, label }) => (
                      <div>
                        <i className={`icon-${value}`}></i> {label}
                      </div>
                    )}
                  />
                  {errors.icon && (
                    <span style={{ color: "red" }}>{errors.icon.message}</span>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="image">Category Image:</Label>
                  <UploadImage control={control} name="image" />
                  {errors.image && (
                    <span style={{ color: "red" }}>{errors.image.message}</span>
                  )}
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button type="submit" color="primary">
                  Save Changes
                </Button>
                <Button color="secondary" onClick={toggleModal}>
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          </>
        }
      />
    </div>
  );
};

export default EditCategory;

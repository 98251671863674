import { getCartTotal } from "../../../../../Services/Ecommerce.service";
import {
  MarginLeft,
  MarginRight,
  PaddingRight,
  Price,
  ProductName,
  Quantity,
  Sub_total,
} from "../../../../../Constant";
import { H6, P } from "../../../../../AbstractElements";
import InvoiceDescription from "./InvoiceDescription";
import MainInvoiceHeader from "./MainInvoiceheader";
import PrintInvoice from "./PrintInvoice";
import { Col, Label, Row, Table, Container, CardBody, Card } from "reactstrap";
import React, { Component, Fragment } from "react";
import "../MainOrder.css";

class ItemDescription extends Component {
  render() {
    const { cart, symbol, data } = this.props;
    const orderList = data?.order_data?.order_cart || []; // Ensure orderList is an array even if data is not yet available

    return (
      <Fragment>
        <Container>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <div className="invoice">
                    <div>
                      <MainInvoiceHeader data={data} />
                      <InvoiceDescription data={data} />

                      <div
                        className="table-responsive invoice-table"
                        id="table"
                      >
                        <Table bordered striped>
                          <thead>
                            <tr>
                              <th className="item">
                                <H6 attrH6={{ className: "p-2 mb-0" }}>
                                  {"Product Code"}
                                </H6>
                              </th>
                              <th className="item">
                                <H6 attrH6={{ className: "p-2 mb-0" }}>
                                  {ProductName}
                                </H6>
                              </th>
                              <th className="item">
                                <H6 attrH6={{ className: "p-2 mb-0" }}>
                                  {"Shop Name"}
                                </H6>
                              </th>
                              <th className="quantity">
                                <H6 attrH6={{ className: "p-2 mb-0" }}>
                                  {Quantity}
                                </H6>
                              </th>
                              <th className="Rate">
                                <H6 attrH6={{ className: "p-2 mb-0" }}>
                                  {Price}
                                </H6>
                              </th>
                              <th className="subtotal">
                                <H6 attrH6={{ className: "p-2 mb-0" }}>
                                  {Sub_total}
                                </H6>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {orderList.length > 0 ? (
                              orderList.map((item) => (
                                <tr key={item.id}>
                                  <td>
                                    <Label>{"PID" + item.id}</Label>
                                  </td>
                                  <td>
                                    <Label>{item.name}</Label>
                                  </td>
                                  <td>
                                    <Label>
                                      {item.product_object.business.vendor_name}
                                    </Label>
                                  </td>
                                  <td>
                                    <P
                                      attrPara={{
                                        className: "itemtext digits",
                                      }}
                                    >
                                      {item.quantity}
                                    </P>
                                  </td>
                                  <td>
                                    <P
                                      attrPara={{
                                        className: "itemtext digits",
                                      }}
                                    >
                                      {"৳"} {item.unit_price}
                                    </P>
                                  </td>
                                  <td className="payment digits">
                                    <P
                                      attrPara={{
                                        className: "itemtext digits",
                                      }}
                                    >
                                      {"৳"} {item.total_amount}
                                    </P>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="4">No items found</td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                        <br />
                        <Row>
      <Col md="4">
        <P>
          Payment Method: {data?.order_data?.payment_method}
        </P>
        <P>
          Delivery Type: {data?.order_data?.shipping_method}
        </P>
        <P>
          Order Note: {data?.order_data?.order_note || "N/A"}
        </P>
      </Col>
      <Col md="8">
        <div className="text-md-end row">
          <div className="div" style={{ flex: "1" }}></div>
          <div id="project" style={{ flex: "1" }}>
            {[
              {
                label: "Subtotal:",
                value: data?.order_data?.subtotal,
              },
              {
                label: "Discount:",
                value: data?.order_data?.coupon_discount,
              },
              {
                label: "Campaign:",
                value: data?.order_data?.coupon_text,
              },
              {
                label: "Delivery Charge:",
                value: data?.order_data?.shipping_charge,
              },
              {
                label: `${data?.order_data?.payment_method} Charge:`,
                value: data?.order_data?.payment_gateway_amount,
              },
            ].map((item, index) => (
              <div
                className="project-row"
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "5px",
                }}
              >
                <H6 style={{ fontWeight: "bold" }}>
                  {item.label}
                </H6>
                <p
                  style={{
                    margin: 0,
                    fontWeight: item.bold ? "bold" : "normal",
                    textAlign: "right",
                    flex: "1 0 auto",
                    wordWrap: "break-word",
                    wordBreak: "break-all",
                  }}
                >
                  {item.value}
                </p>
              </div>
            ))}
            <hr />
            <div
              className="project-row"
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "5px",
              }}
            >
              <H6 style={{ fontWeight: "bold" }}>
                {"Total:"}
              </H6>
              <p
                style={{
                  margin: 0,
                  fontWeight: "bold",
                  textAlign: "right",
                  flex: "1 0 auto",
                }}
              >
                {data?.order_data?.total}
              </p>
            </div>
          </div>
        </div>
      </Col>
    </Row>
                      </div>
                      <PrintInvoice />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default ItemDescription;

import { Container, Row, Col, Card, CardBody } from "reactstrap";
import HeaderCard from "../../Components/Common/Component/HeaderCard";
import Table from "../../CommonElements/Table/Table";
import { tableColumns } from "./components/columns";
import { Breadcrumbs } from "../../AbstractElements";
import { Fragment } from "react";
import TableHeader from "../../CommonElements/TableHeader/TableHeader";
import CCardHeader from "../../CommonElements/CardHeader/CardHeader";
import CAsyncSelect from "../../CommonElements/CommonSelect/AsyncSelect";
import { getCommonParams } from "../../utils/helper";
import { getAllBusinessList } from "../../Services/businessService";
import CSelect from "../../CommonElements/CommonSelect/Select";
import useSellerOrders from "./hooks/useSellerOrders";

const SellerOrdersPage = () => {
  const {
    data,
    totalRows,
    handlePageChange,
    handlePageSizeChange,
    handleSearch,
    selectSeller,
  } = useSellerOrders();
  return (
    <Fragment>
      <Breadcrumbs parent="Home" title="Orders" mainTitle="Seller Orders" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CCardHeader
                title="Seller Orders"
                rightContent={
                  <div>
                    <p>Filter By Seller:</p>
                    <CAsyncSelect
                      name="product"
                      fetchOptions={async (value) => {
                        return getAllBusinessList(getCommonParams(value));
                      }}
                      modifyOptions={(busineses) => {
                        return [
                          { value: "all", label: "All" },
                          ...busineses.map((business) => ({
                            label: business.business_name,
                            value: business.id,
                          })),
                        ];
                      }}
                      handleSelectChange={selectSeller}
                    />
                  </div>
                }
              />
              <TableHeader onSearch={handleSearch} />
              <CardBody>
                <Table
                  data={data}
                  columns={tableColumns}
                  onPageChange={handlePageChange}
                  onPageSizeChange={handlePageSizeChange}
                  totalRows={totalRows}
                  pageSize={10}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default SellerOrdersPage;

import React, { Fragment, useEffect } from 'react';
import { H3, H2,H4, P, Image } from '../../../../AbstractElements';
import { InvoiceHash, IssuedJan, PaymentDue } from '../../../../Constant';
import { Col, Media, Row } from 'reactstrap';
import logo1 from '../../../../assets/images/other-images/logo-login.png';
import orderStore from '../../../../store/orderStore';
import { useParams } from 'react-router';

const InvoiceHeader = ({data}) => {

 const {id} = useParams()
 const {fetchInvoiceFromOrderId,updatedOrderIdInvoiceList} = orderStore();

 useEffect(()=>
{
  fetchInvoiceFromOrderId(id);
},[fetchInvoiceFromOrderId,id])

  return (
    <Fragment>
      <Row className='invo-header'>
        <Col sm='6'>
          <Media>
            <div className='media-left'>
              <Image attrImage={{ className: 'media-object img-60', src: logo1, alt: '' }} />
            </div>
            <Media body className='m-l-20 text-right'>
              <H4 attrH4={{ className: 'media-heading f-w-600' }}>Window</H4>
              <P>
                window@gmail.com
                <br />
                <span className='digits'>{'019XXXXXXXX'}</span>
              </P>
            </Media>
          </Media>
        </Col>
        <Col sm='6'>
          <div className='text-md-end text-xs-center'>
            <H4>
              {"Main Order#"}
              <span className='digits counter'>{data?.main_order_id}</span>
            </H4>
            <H3>
              {InvoiceHash}
              <span className='digits counter'>{id}</span>
            </H3>
            <P>
              {"Date Issued:"}
              <span className='digits'>{data?.order_data?.create_at.slice(0,10)}</span>
            </P>
          </div>
        </Col>
      </Row>
      <hr />
    </Fragment>
  );
};
export default InvoiceHeader;

import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import HeaderCard from '../../Components/Common/Component/HeaderCard';
import Table from '../../CommonElements/Table/Table';
import { tableColumns } from './components/columns';
import { Breadcrumbs } from '../../AbstractElements';
import { Fragment } from 'react';
import useCoupons from './hooks/useCoupons';
import TableHeader from '../../CommonElements/TableHeader/TableHeader';
import AddCoupon from './components/add-coupon';
import CCardHeader from '../../CommonElements/CardHeader/CardHeader';


const CouponsPage = () => {
  const { data, totalRows, handlePageChange, handlePageSizeChange, handleSearch } = useCoupons()
  return (
    <Fragment>
      <Breadcrumbs parent="Table" title="All Coupons" mainTitle="All Coupons" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CCardHeader title="All Coupons" rightContent={<AddCoupon/>}/>
              <TableHeader onSearch={handleSearch}/>
              <CardBody>
                <Table
                  data={data}
                  columns={tableColumns}
                  onPageChange={handlePageChange}
                  onPageSizeChange={handlePageSizeChange}
                  totalRows={totalRows}
                  pageSize={10}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

};

export default CouponsPage;
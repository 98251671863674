import { icon } from "leaflet";

export const MENUITEMS = [
  {
    menutitle: "General",
    menucontent: "Dashboards,Widgets",
    Items: [
      {
        path: `/dashboard/default`,
        icon: "home",
        title: "DashBoard",
        type: "link",
      },
      { path: `/app/customer`, icon: "user", title: "Customer", type: "link" },
      {
        path: `/app/business`,
        icon: "task",
        title: "Onboard Business",
        type: "link",
      },
    ],
  },

  {
    menutitle: "Applications",
    menucontent: "Ready to use Apps",
    Items: [
      {
        path: `/app/coupon/coupon-list`,
        icon: "project",
        title: "Coupons",
        type: "link",
        active: false,
        badge: "badge badge-light-secondary",
        badgetxt: "offer",
      },
      {
        title: "Deals of The Day",
        icon: "ecommerce",
        type: "sub",
        badge: "badge badge-light-secondary",
        badgetxt: "deals",
        active: true,
        path: `/app/deal-of-the-day/deals-product/`,
        type: "link",
      },
      //Payment Method
      {
        path: `/app/payment-methods`,
        icon: "blog",
        title: "Payment Methods",
        type: "link",
      },
      {
        path: `/app/shipping-methods`,
        icon: "blog",
        title: "Shipping Methods",
        type: "link",
      },

      {
        title: "Order Panel",
        icon: "calendar",
        type: "sub",
        badge: "badge badge-light-secondary",
        badgetxt: "selling",
        active: true,
        children: [
          {
            path: `/app/all-orders/`,
            type: "link",
            title: "All Orders",
          },
          {
            path: `/app/seller-orders/`,
            type: "link",
            title: "Seller Orders",
          }
        ],
      },
      //WithDrawal panel
      {
        path: `/app/withdrawal`,
        icon: "to-do",
        title: "WithDrawal",
        type: "link",
      },
      {
        path: `/app/shipping-withdraw`,
        icon: "button",
        title: "Shipping Withdraw",
        type: "link",
      },
      {
        title: "Payment Gateway ",
        icon: "calendar",
        type: "sub",
        active: true,
        children: [
          { path: `/app/payment-gateway/logs`, type: "link", title: "PG Logs" },
          {
            path: `/app/payment-gateway/history`,
            type: "link",
            title: "PG History",
          },
        ],
      },
      {
        title: "Ecommerce",
        icon: "ecommerce",
        type: "sub",
        active: false,
        children: [
          { path: `/app/ecommerce/product`, title: "Products", type: "link" },
          {
            path: `/app/ecommerce/category`,
            title: "Category",
            type: "link",
          },
          { path: `/app/ecommerce/slider`, title: "Home Slider", type: "link" },
          { path: `/app/home-ads`, title: "Home Ads", type: "link" },
          {
            path: `/app/ecommerce/business-type`,
            title: "Business Type",
            type: "link",
          },
        ],
      },
      {
        title: "Site Settings",
        icon: "editors",
        type: "sub",
        active: true,
        children: [
          { path: `/app/site-info`, type: "link", title: "Site Info" },
          {
            path: `/app/site-features`,
            type: "link",
            title: "Site Features",
          },
        ],
      },
      {
        title: "Blog",
        icon: "blog",
        type: "sub",
        active: true,
        children: [
          { path: `/app/blogs`, type: "link", title: "Blogs" },
          {
            path: `/app/create-blog`,
            type: "link",
            title: "Create Blog",
          },
        ],
      },
      {
        title: "Profile",
        icon: "user",
        path: `/app/users/profile`,
        type: "sub",
        bookmark: true,
        active: false,
        children: [
          { path: `/app/users/profile`, type: "link", title: "User Profile" },
          // { path: `/app/users/edit`, type: "link", title: "User Edit" },
          // { path: `/app/users/cards`, type: "link", title: "User Cards" },
        ],
      },
    ],
  },
];

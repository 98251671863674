import { H5 } from '../../../AbstractElements';
import React, { Fragment } from 'react';
import { CardHeader } from 'reactstrap';
import './headerCard.css'

const HeaderCard = ({ title, span1, span2, mainClasses ,button}) => {
  return (
    <Fragment>
      <CardHeader className={`${mainClasses ? mainClasses : ''}`}>
        <H5>{title}</H5>
        {span1 ? <span>{span1}</span> : ''}
        {span2 ? <span>{span2}</span> : ''}
        {button ? <button className="header-button">{button}</button> : ""}
      </CardHeader>
    </Fragment>
  );
};

export default HeaderCard;

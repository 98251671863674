import apiEndpoints from "./apiEndpoints";
import { jwtDecode } from "jwt-decode";

export const isTokenExpired = () => {
    const token = localStorage.getItem(apiEndpoints.accessTokenKey);
    const decodedToken = jwtDecode(token);
    const time = Math.floor(new Date().getTime() / 1000);
    return time > decodedToken.exp;
  }

  export const getRefreshToken = () => {
    return fetch(process.env.PUBLIC_URL + apiEndpoints.refreshToken, {
      method: "POST",
      credentials: "include"
    });
  }

  export const getToken = () => {
    return localStorage.getItem(apiEndpoints.accessTokenKey);
  }

  export const setToken = (token,value) => {
    return localStorage.setItem(token, value);
  }


  export const removeToken = () => {
    return localStorage.removeItem(apiEndpoints.accessTokenKey);
  }

  export default {
    isTokenExpired,
    getRefreshToken,
    getToken,
    setToken,
    removeToken
  }
import React from "react";
import {
  Card,
  CardBody,
  Col
} from "reactstrap";

import HeaderCard from "../../../Common/Component/HeaderCard";
import { FaCartPlus } from "react-icons/fa"; // Import the Add to Cart icon
import './MainOrder.css'; // Import the CSS file for additional styling
import { Navigate, useNavigate,useParams } from "react-router";



const MainOrder = () => {

  const navigate = useNavigate();
  const {id} = useParams()
  const handleButtonClick = () =>
{
  navigate(`/app/seller/seller-order-list/Main-order/${id}`)
}
  return (
    <div>    
      <Col sm="12" xl="12">
        <Card>
          <HeaderCard title={"View Main Order"} />
          <CardBody style={{ marginTop: "20px", textAlign: 'center' }}>
           
            <button className="btn-custom" onClick={handleButtonClick}>
              <FaCartPlus className="btn-icon" /> More
            </button>
          </CardBody>
        </Card>
      </Col>
    </div>
  );
};


export default MainOrder;

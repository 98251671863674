import Markdown from "react-markdown";
import DeleteBlog from "./delete-blog";
import EditBlog from "./edit-blog";
import remarkGfm from "remark-gfm";
import { useState } from "react";
import { Eye } from "react-feather";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

const MAX_LENGTH = 100; // Limit for truncation

export const tableColumns = [
  {
    name: "Title",
    selector: (row) => row,
    center: true,
    sortable: true,
    cell: (row) => <span className="f-w-500">{row?.title}</span>,
  },
  {
    name: "Content",
    selector: (row) => row,
    center: true,
    sortable: true,
    cell: (row) => {
      return (
        <ContentCell description={row?.description} />)
    }
  },
  {
    name: "Actions",
    sortable: true,
    center: true,
    cell: (row) => {
      return (
        <div className="d-flex" style={{ gap: "6px" }}>
          <EditBlog blog={row} />
          <DeleteBlog blog={row} />
          <Link to={`/app/blogs/${row?.id}`}>
            <Button
              className="p-1"
              style={{ width: "max-content" }}
              color="primary"
              size="sm"
            >
              <Eye />
            </Button>
          </Link>
        </div>
      );
    },
  },
];


const ContentCell = ({ description }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const MAX_LENGTH = 100;

  return (
    <div className="markdown-content">
      <Markdown
        children={isExpanded ? description : `${description.substring(0, MAX_LENGTH)}...`}
        remarkPlugins={[remarkGfm]}
      />
      {description.length > MAX_LENGTH && (
        <p style={{ cursor: "pointer" }} onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? "Show Less" : "Read More"}
        </p>
      )}
    </div>
  );
};

export default ContentCell;

import React, { useState } from "react";
import { Edit, Plus } from "react-feather";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import RModal from "../../../CommonElements/RModal/RModal";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

// Zod schema for validation
import UploadImage from "../../../CommonElements/UploadImage/upload-image";
import sliderStore from "../../../store/sliderStore";

const sliderSchema = z.object({
  banner_url: z.string().min(1, "Banner url is required"),
  banner_image: z.string().min(1, "Image is required"),
});

const AddSlide = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const store = sliderStore();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({
    mode: "all",
    resolver: zodResolver(sliderSchema),
    defaultValues: {
      banner_url: "",
      banner_image: "",
    },
  });

  const onSubmit = (data) => {
    store.createSlide(data);
    toggleModal();
  };
  return (
    <div>
      <Button
        className="p-1 d-flex align-items-center"
        style={{ width: "max-content" }}
        color="primary"
        size="sm"
        onClick={toggleModal}
      >
        <Plus /> Create Slide
      </Button>
      <RModal
        isOpen={isModalOpen}
        toggleModal={toggleModal}
        title="Add Slide"
        bodyContent={
          <>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <ModalBody>
                <FormGroup>
                  <Label for="name">Banner Url:</Label>
                  <input
                    className="form-control"
                    type="text"
                    {...register("banner_url")}
                  />
                  {errors.banner_url && (
                    <span style={{ color: "red" }}>{errors.banner_url.message}</span>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="image">Banner Image:</Label>
                  <UploadImage control={control} name="banner_image" />
                  {errors.banner_image && (
                    <span style={{ color: "red" }}>{errors.banner_image.message}</span>
                  )}
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button type="submit" color="primary">
                  Create Slide
                </Button>
                <Button color="secondary" onClick={toggleModal}>
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          </>
        }
      />
    </div>
  );
};

export default AddSlide;

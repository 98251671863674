import apiEndpoints from "./apiEndpoints";
import apiServices from "./apiServices"

export const getUserInfo = async() =>
{
    try{
        const response = await apiServices.get(apiEndpoints.getUserInfo)

        return response.data
    }catch(err)
    {
        console.error("Fetching error in user info:",err);
        throw err
    }
}



export const editUserinfo = async(data)=>
{
    try{
        
        const response  = await apiServices.post(apiEndpoints.updateUserInfo,data);
        return response.data
    }catch(err)
    {
        console.error("failed update user info",err)
    }
}




export default {editUserinfo,getUserInfo}
import { create } from "zustand";
import { getAllOrders, getOrderDetails, getSellerOrdersBySellerId, updateOrderStatus } from "../Services/orderServices";

const orderStore = create((set, get) => ({
  orders: [],
  totalOrders: 0,
  sellerOrders: [],
  totalSellerOrder: 0,
  orderDetails: {},

  fetchAllOrder: async (status, params) => {
    try {
      const response = await getAllOrders(status, params);
      set({
        orders: response.data?.results,
        totalOrder: response.data?.count
      });
    } catch (err) {
      console.error("Error while fetching data", err);
    }
  },

  fetchOrdersBySellerId: async (sellerId, params) => {
    try {
      const response = await getSellerOrdersBySellerId(sellerId, params);
      set({
        sellerOrders: response.data?.results,
        totalSellerOrder: response.data?.count
      });
    } catch (err) {
      console.error("Error while fetching data", err);
    }
  },

  fetchOrderDetails: async (orderId) => {
    try {
      const response = await getOrderDetails(orderId);
      set({
        orderDetails: response.data,
      });
    } catch (err) {
      console.error("Error while fetching data", err);
    }
  },
  updateOrderStatus: async (orderId, status) => {
    try {
      const response = await updateOrderStatus(orderId, status);
      await get().fetchAllOrder();
    } catch (err) {
      console.error("Error while fetching data", err);
    }
  },
}));

export default orderStore;

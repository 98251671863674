import React, { useState } from 'react';
import { AlertTriangle, Trash } from 'react-feather';
import { Button, ModalBody, ModalFooter } from 'reactstrap';
import couponStore from '../../../store/couponStore';
import RModal from '../../../CommonElements/RModal/RModal';

const DeleteCoupon = ({ coupon }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const { deleteCouponById } = couponStore();
    const handleDelete = async () => {
        await deleteCouponById(coupon?.id);
    };
    return (
        <div>
            <Button className="p-1" style={{ width: "max-content" }} color='danger' size="sm" onClick={toggleModal}>
                <Trash />
            </Button>
            <RModal
                isOpen={isModalOpen}
                toggleModal={toggleModal}
                title=""
                bodyContent={
                    <>
                        <ModalBody>
                            <div style={{ textAlign: "center" }}>
                                <AlertTriangle size={48} className='text-danger' />
                                <h5 className="mt-3">Are you sure you want to delete this coupon?</h5>
                                <p>
                                    This action <strong>cannot be undone</strong>. The coupon will be permanently deleted,
                                    and all data associated with it will be lost.
                                </p>
                            </div>
                        </ModalBody>
                        <ModalFooter className='border-0'>
                            <Button color="danger" onClick={handleDelete}>
                                Delete
                            </Button>
                            <Button color="secondary" onClick={toggleModal}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    </>
                }
            />
        </div>
    );
};

export default DeleteCoupon;
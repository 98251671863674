import { Button, Col, Media, Row } from "reactstrap";
import { Image } from "../../../AbstractElements";
import moment from "moment";
import ToggleBtn from "../../../CommonElements/toggleButton";
import {
  notificationType,
  showNotification,
} from "../../../utils/notification";
import { useState } from "react";

export const homeAddColumns = [
  {
    name: "Add Image",
    selector: (row) => row,
    cell: (row) => (
      <Image
        attrImage={{
          src: row?.banner_image,
          style: {
            width: 60,
            fontSize: 14,
            padding: 4,
          },
          alt: row?.name,
        }}
      />
    ),
  },
  {
    name: "Add URL",
    selector: (row) => row,
    center: true,
    sortable: true,
    cell: (row) => <a className="f-w-500" href={row?.banner_url} target="_blank">{row?.banner_url}</a>,
  }
];

import { useEffect, useState } from "react";
import { useStore } from "zustand";
import shippingMethodStore from "../../../store/shippingMethodStore";
import { getSiteInfo, updateSiteInfo } from "../../../Services/siteInfoService";

const useSiteInfo = (reset) => {
  const [siteInfo, setSiteInfo] = useState({})
  const [loading, setLoading] = useState(false);
  const [refetch, setRefetch] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let res = await getSiteInfo();
        setSiteInfo(res?.data);
        const defaultValues = {
          site_name: res?.data.site_name || "",
          site_logo: res?.data.site_logo || "",
          email: res?.data.support_info?.email || "",
          mobile: res?.data.support_info?.mobile || "",
          map_url: res?.data.support_info?.map_url || "",
          company_address: res?.data?.company_address || "",
          twitter: res?.data.social?.twitter || "",
          facebook: res?.data.social?.facebook || "",
          linkedin: res?.data.social?.linkedin || "",
          instagram: res?.data.social?.instagram || "",
        };
        console.log(defaultValues, "default values")
        reset(defaultValues);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    })();
  }, [refetch]);

  const updateSiteInformation = async (data) => {
    try {
      setLoading(true);
      let res = await updateSiteInfo(data);
      setRefetch(!refetch);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }

  return {
    siteInfo,
    loading,
    updateSiteInfo,
  };
};

export default useSiteInfo;

import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import { Col, Container, Row } from 'reactstrap';
import OrderHistoryTableCard from './OrderHistoryTableCard';
import Widgets1 from '../../Common/CommonWidgets/Widgets1';
import { WidgetsData, WidgetsData2, WidgetsData3 } from '../../../Data/DefaultDashboard';


const SellerFinance = () => {
  return (
    <Fragment>
      <Breadcrumbs parent='Window' title='Seller Finance' mainTitle='seller finance' />
      <Container fluid={true} className='orderhistory'>
        <Row>
          <Col xl='4'>
            <Widgets1 data={WidgetsData} />
          </Col>
          <Col xl='4'>
            <Widgets1 data={WidgetsData2} />
          </Col>
          <Col xl='4'>
            <Widgets1 data={WidgetsData3} />
          </Col>
        </Row>
        <OrderHistoryTableCard />
      </Container>
    </Fragment>
  );
};
export default SellerFinance;
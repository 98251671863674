import React, { useState } from "react";
import {
    Modal,
    ModalHeader,
} from "reactstrap";
// ReusableModal => RModal
const RModal = ({
    isOpen,
    toggleModal,
    title,
    bodyContent,
    size="md"
}) => {
    return (
        <Modal size={size} centered isOpen={isOpen} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>{title}</ModalHeader>
            {bodyContent}
        </Modal>
    );
};

export default RModal;

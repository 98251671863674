import { Button, Col, Media, Row } from "reactstrap";
import { Image } from "../../../AbstractElements";
import moment from "moment";
import ToggleBtn from "../../../CommonElements/toggleButton";
import {
  notificationType,
  showNotification,
} from "../../../utils/notification";
import { Edit, Trash } from "react-feather";
import { useState } from "react";
import DeletePaymentMethod from "./delete-payment-method";
import EditPaymentMethod from "./edit-payment-method";
import paymentMethodStore from "../../../store/paymentMethodStore";

export const tableColumns = [
  {
    name: "Method",
    selector: (row) => row,
    cell: (row) => (
      <Image
        attrImage={{
          src: row?.logo,
          style: {
            width: 60,
            fontSize: 14,
            padding: 4,
          },
          alt: row?.name,
        }}
      />
    ),
  },
  {
    name: "Name",
    selector: (row) => row.name,
    center: true,
    sortable: true,
    cell: (row) => <span className="f-w-500">{row?.name}</span>,
  },
  {
    name: "Charge",
    selector: (row) => row,
    center: true,
    cell: (row) => (
      <span className="badge badge-light-primary">৳{row.charge}</span>
    ),
  },
  {
    name: "Status",
    selector: (row) => row,
    cell: (row) => {
      const { togglePaymentMethodStatus } = paymentMethodStore();
      const handleToggleStatus = async (id) => {
        try {
          await togglePaymentMethodStatus(id);
          showNotification({
            type: notificationType.SUCCESS,
            message: "Status updated!",
          });
        } catch (err) {
          showNotification({
            type: notificationType.DANGER,
            message: "Status update Failed!",
          });
          console.error("error while toggling status: ", err);
        }
      };

      return (
        <ToggleBtn
          value={row.status}
          onToggle={() => handleToggleStatus(row.id)}
        />
      );
    },
  },
  {
    name: "Test Mode",
    selector: (row) => row,
    cell: (row) => {
      const { togglePaymentMethodTestMode } = paymentMethodStore();
      const handleToggleStatus = async (id) => {
        try {
          await togglePaymentMethodTestMode(id);
          showNotification({
            type: notificationType.SUCCESS,
            message: "Status updated!",
          });
        } catch (err) {
          showNotification({
            type: notificationType.DANGER,
            message: "Status update Failed!",
          });
          console.error("error while toggling status: ", err);
        }
      };

      return (
        <ToggleBtn
          value={row.test_mode}
          onToggle={() => handleToggleStatus(row.id)}
        />
      );
    },
  },
  {
    name: "Actions",
    sortable: true,
    center: true,
    cell: (row) => {
      return (
        <div className="d-flex" style={{ gap: "6px" }}>
          <EditPaymentMethod method={row} />
          <DeletePaymentMethod method={row} />
        </div>
      );
    },
  },
];

import { useEffect, useState } from "react";
import { useStore } from "zustand";
import businessStore from "../../../store/businessStore";

const useBusiness = () => {
    const [searchKeyword, setSearchKeyword] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const { fetchAllBusiness, businessList, totalBusiness } = useStore(businessStore);

    useEffect(() => {
        (async () => {
            try {
                await fetchAllBusiness({ page: currentPage, count: pageSize, keyword: searchKeyword });
            } catch (error) {
                console.log(error);
            }
        })()
    }, [currentPage, pageSize, searchKeyword]);

    function handlePageChange(page) {
        setCurrentPage(page);
    }

    function handlePageSizeChange(pageSize) {
        setPageSize(pageSize);
    }

    function handleSearch(value) {
        setSearchKeyword(value);
    }

    return { data: businessList, totalRows: totalBusiness, handlePageChange, handlePageSizeChange, handleSearch }
};

export default useBusiness;
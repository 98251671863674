import React from "react";
import { Spinner } from "reactstrap";

const Loader = () => {
  return (
    <div className="m-4">
      <Spinner
        color="warning"
        style={{
          height: "3rem",
          width: "3rem",
        }}
        type="grow"
      >
        Loading...
      </Spinner>
    </div>
  );
};

export default Loader;

import { useEffect, useState } from "react";
import { useStore } from "zustand";
import dealsOfTheDay from "../../../store/dealsOfTheDay";

const useDealsOfTheDay = () => {
    const [searchKeyword, setSearchKeyword] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const { fetchDeals, totalDeals, deals } = useStore(dealsOfTheDay);

    useEffect(() => {
        (async () => {
            try {
                await fetchDeals({ page: currentPage, count: pageSize, keyword: searchKeyword });
            } catch (error) {
                console.log(error);
            }
        })()
    }, [currentPage, pageSize, searchKeyword]);

    function handlePageChange(page) {
        setCurrentPage(page);
    }

    function handlePageSizeChange(pageSize) {
        setPageSize(pageSize);
    }

    function handleSearch(value) {
        setSearchKeyword(value);
    }

    return { data: deals, totalRows: totalDeals, handlePageChange, handlePageSizeChange, handleSearch }
};

export default useDealsOfTheDay;
import AsyncSelect from "react-select/async"; // Ensure you're using AsyncSelect
import { Controller } from "react-hook-form";
import { useRef, useState } from "react";
import { select_styles } from "./select-style";

const CAsyncSelect = ({
  name,
  control,
  placeholder = "Search...",
  fetchOptions,
  modifyOptions,
  components,
  handleSelectChange,
  isMulti = false, // Default to single select, but allow multi-select
  ...rest
}) => {
  const loadOptions = async (inputValue) => {
    const response = fetchOptions && (await fetchOptions(inputValue));
    if (response?.status === 200) {
      const fetchedOptions = response.data.results;
      const modifiedOptions = modifyOptions
        ? modifyOptions(fetchedOptions)
        : [];
      return modifiedOptions; // Return the modified options
    }
    return []; // Return an empty array if no options are fetched
  };

  let containerRef = useRef(null);

  // For uncontrolled scenario (when no control is passed)
  const [selectedOption, setSelectedOption] = useState(null);

  const renderSelect = (field) => (
    <AsyncSelect
      {...field}
      isSearchable
      cacheOptions
      menuPosition="fixed"
      menuPortalTarget={document.body}
      loadOptions={loadOptions}
      defaultOptions
      className="react-select"
      classNamePrefix="select"
      classNames={{
        menuPortal: () => "pointer-events-auto",
        option: (state) =>
          state.isSelected
            ? "bg-secondary text-white"
            : state.isFocused
            ? "bg-secondary text-white"
            : "",
        menu: () => "bg-primary",
        control: (state) =>
          state.isFocused
            ? "border-primary shadow-none"
            : rest.error
            ? "border-destructive"
            : "",
      }}
      styles={select_styles}
      components={components} // Pass custom components if provided
      placeholder={placeholder}
      isMulti={isMulti} // Enable multi-select
      onChange={(option) => {
        field?.onChange && field.onChange(option); // Call onChange from react-hook-form, if exists
        handleSelectChange && handleSelectChange(option); // Pass the selected options
        if (!control) setSelectedOption(option); // Update state if uncontrolled
      }}
      value={control ? field.value : selectedOption} // Use form value if controlled, or local state if uncontrolled
      {...rest}
    />
  );

  return (
    <div ref={containerRef}>
      {control ? (
        <Controller
          name={name}
          control={control}
          render={({ field, fieldState: { error } }) => renderSelect(field)}
        />
      ) : (
        // If no control is provided, just render the AsyncSelect directly
        renderSelect({ onChange: () => {}, value: selectedOption })
      )}
    </div>
  );
};

export default CAsyncSelect;

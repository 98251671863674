import { create } from "zustand";
import { getAllOrders, getOrderDetails, getSellerOrdersBySellerId, updateOrderStatus } from "../Services/orderServices";
import { getSiteFeatures, updateSiteFeature } from "../Services/siteFeaturesService";

const siteFeatureStore = create((set, get) => ({
  siteFeatures: [],
  totalSiteFeatures: 0,

  fetchAllSiteFeatures: async (params) => {
    try {
      const response = await getSiteFeatures(params);
      set({
        siteFeatures: response.data?.results,
        totalSiteFeatures: response.data?.count
      });
    } catch (err) {
      console.error("Error while fetching data", err);
    }
  },
  updateSiteFeature: async (id, data) => {
    try {
      const response = await updateSiteFeature(id, data);
      await get().fetchAllSiteFeatures();
    } catch (err) {
      console.error("Error while fetching data", err);
    }
  },
}));

export default siteFeatureStore;

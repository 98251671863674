import apiEndpoints from "./apiEndpoints";
import apiServices from "./apiServices"

export const getAllBusinessList = async (params) => {
    try {
        const queryString = new URLSearchParams(params).toString();
        const response = await apiServices.get(`${apiEndpoints.getBusiness}?${queryString}`);
        return response.data
    } catch (err) {
        console.error("Fetching error in business list:", err);
        throw err
    }
}

export const getAllBusinessSimple = async () => {
    try {
        const response = await apiServices.get(apiEndpoints.getProductBusinessSimple)
        return response.data
    } catch (err) {
        console.error("error while fetching business Simple:", err)
        throw err
    }
}

export const toggleStatus = async (id) => {
    try {

        const response = await apiServices.get(apiEndpoints.toggleStatus + id);
        return response.data
    } catch (err) {
        console.error("failed toggle status", err)
    }
}

export const toggleCommission = async (id) => {
    try {
        const response = await apiServices.get(apiEndpoints.toggleCommission + id);
        return response.data
    } catch (err) {
        console.error("failed toggle commission", err)
    }
}

export const toggleFeature = async (id) => {
    try {
        const response = await apiServices.get(apiEndpoints.toggleFeature + id);
        return response.data
    } catch (err) {
        console.error("failed toggle feature", err)
        throw err
    }
}

export const getProductByBusiness = async (param) => {
    try {
        const response = await apiServices.get(apiEndpoints.getProductByBusiness + param);
        return response.data
    } catch (err) {
        console.error("error while get product form business", err)
        throw err
    }
}




export default { getAllBusinessList, getProductByBusiness }
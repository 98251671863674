import { Button, Form, FormGroup, Label, input, ModalBody, ModalFooter, Card, CardBody } from "reactstrap";
import { useForm } from "react-hook-form";
import CCardHeader from "../../CommonElements/CardHeader/CardHeader";
import useSiteInfo from "./hooks/useSiteInfo";
import UploadImage from "../../CommonElements/UploadImage/upload-image";

const SiteInfoPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    mode: "all",
    defaultValues: {
      site_logo: "",
      site_name: "",
      email: "",
      mobile: "",
      map_url: "",
      company_address: "",
      twitter: "",
      facebook: "",
      linkedin: "",
      instagram: "",
    },
  });
  const { siteInfo, loading, updateSiteInfo } = useSiteInfo(reset);

  const onSubmit = (data) => {
    const formattedData = {
      site_name: data.site_name,
      site_logo: data.site_logo,
      support_info: {
        email: data.email,
        mobile: data.mobile,
        map_url: data.map_url,
      },
      company_address: data.company_address,
      social: {
        twitter: data.twitter,
        facebook: data.facebook,
        linkedin: data.linkedin,
        instagram: data.instagram,
      },
    };
    updateSiteInfo(formattedData);
  };

  return (
    <Card>
      <CCardHeader title="Site Info" />
      <CardBody>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label for="site_name">Site Name:</Label>
            <input
              className="form-control"
              type="text"
              {...register("site_name", { required: "Site Name is required" })}
            />
            {errors.site_name && (
              <span style={{ color: "red" }}>{errors.site_name.message}</span>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="site_logo">Site Logo:</Label>
            <UploadImage control={control} name="site_logo" />
            {errors.site_logo && (
              <span style={{ color: "red" }}>{errors.site_logo.message}</span>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="email">Support Email:</Label>
            <input
              className="form-control"
              type="email"
              {...register("email", { required: "Support Email is required" })}
            />
            {errors.email && (
              <span style={{ color: "red" }}>{errors.email.message}</span>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="mobile">Support Mobile:</Label>
            <input
              className="form-control"
              type="text"
              {...register("mobile", { required: "Support Mobile is required" })}
            />
            {errors.mobile && (
              <span style={{ color: "red" }}>{errors.mobile.message}</span>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="map_url">Map URL:</Label>
            <input
              className="form-control"
              type="text"
              {...register("map_url", { required: "Map URL is required" })}
            />
            {errors.map_url && (
              <span style={{ color: "red" }}>{errors.map_url.message}</span>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="company_address">Company Address:</Label>
            <input
              className="form-control"
              type="text"
              {...register("company_address", { required: "Company Address is required" })}
            />
            {errors.company_address && (
              <span style={{ color: "red" }}>{errors.company_address.message}</span>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="twitter">Twitter:</Label>
            <input className="form-control" type="text" {...register("twitter")} />
          </FormGroup>
          <FormGroup>
            <Label for="facebook">Facebook:</Label>
            <input className="form-control" type="text" {...register("facebook")} />
          </FormGroup>
          <FormGroup>
            <Label for="linkedin">LinkedIn:</Label>
            <input className="form-control" type="text" {...register("linkedin")} />
          </FormGroup>
          <FormGroup>
            <Label for="instagram">Instagram:</Label>
            <input className="form-control" type="text" {...register("instagram")} />
          </FormGroup>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button type="submit" color="primary" className="me-3">
              {
                loading ?
                  "Saving..."
                  :
                  "Save Changes"
              }
            </Button>
            <Button color="secondary">
              Cancel
            </Button>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
};

export default SiteInfoPage;

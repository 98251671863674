import React, { useState } from 'react';
import { Edit, Plus } from 'react-feather';
import { Button, Form, FormGroup, Label, Input, ModalBody, ModalFooter } from 'reactstrap';
import RModal from '../../../CommonElements/RModal/RModal';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import couponStore from '../../../store/couponStore';
import DatePicker from 'react-datepicker';
import { getAllCategory } from '../../../Services/productCategoryService';
import { getCommonParams } from '../../../utils/helper';
import CSelect from '../../../CommonElements/CommonSelect/Select';
import CAsyncSelect from '../../../CommonElements/CommonSelect/AsyncSelect';

// Zod schema for validation
const couponSchema = z.object({
    coupon_code: z.string().min(1, "Coupon Code is required"),
    coupon_type: z.object({
        value: z.string().min(1, { message: "Please select a Coupon Type." }),  // Ensure 'value' is a string
        label: z.string()  // Label is also a string
    }).refine(data => data.value && data.label, { message: "Coupon Type is required" }),
    coupon_value: z.preprocess((val) => {
        const parsed = parseFloat(val);
        return isNaN(parsed) ? undefined : parsed; // Return undefined if not a valid number
    }, z.number().positive("Coupon Value must be a positive number")),
    usage_limit: z.preprocess((val) => {
        const parsed = parseFloat(val);
        return isNaN(parsed) ? undefined : parsed; // Return undefined if not a valid number
    }, z.number().positive("Usage Limit must be a positive number")),
    start_date: z.date().refine((val) => !isNaN(val), { message: "Invalid start date" }),
    end_date: z.date().refine((val) => !isNaN(val), { message: "Invalid end date" }),
    exclude_category: z.array(z.object({
        value: z.union([z.string(), z.number()]),  // 'value' can be a string or a number
        label: z.string()   // 'label' is still a string
    })).optional(),
});

const AddCoupon = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const store = couponStore(); // Zustand store hook for managing state
    // Initialize useForm with zod schema resolver
    const { register, handleSubmit, formState: { errors }, reset, control } = useForm({
        mode: "all",
        resolver: zodResolver(couponSchema),
        defaultValues: { // Use defaultValues instead of values
            coupon_code: '',
            coupon_type: '',
            coupon_value: 0,
            usage_limit: 0,
            start_date: '',
            end_date: '',
            exclude_category: [],
        },
    });

    const onSubmit = (data) => {
        const couponTypeValue = data?.coupon_type?.value;
        const excludeCategoryIds = data?.exclude_category?.map(cat => cat.value);
        const newData = { ...data, coupon_type: couponTypeValue, exclude_category: excludeCategoryIds };
        store.createNewCoupon(newData);
        toggleModal();
    };

    return (
        <div>
            <Button className="p-1 d-flex align-items-center" style={{ width: "max-content" }} color='primary' size="sm" onClick={toggleModal}>
                <Plus /> Create Coupon
            </Button>
            <RModal
                isOpen={isModalOpen}
                toggleModal={toggleModal}
                title="Add Coupon"
                bodyContent={
                    <>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <ModalBody>
                                <FormGroup>
                                    <Label for="coupon_code">Coupon Code:</Label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        {...register('coupon_code')}
                                    />
                                    {errors.coupon_code && <span style={{ color: 'red' }}>{errors.coupon_code.message}</span>}
                                </FormGroup>

                                <FormGroup>
                                    <Label for="coupon_type">Coupon Type:</Label>
                                    <CSelect
                                        name="coupon_type"
                                        control={control}
                                        options={[
                                            {
                                                label: "Percentage",
                                                value: "Percentage",
                                            },
                                            {
                                                label: "Fixed",
                                                value: "Fixed",
                                            }
                                        ]}
                                    />
                                    {errors.coupon_type && <span style={{ color: 'red' }}>{errors.coupon_type.message}</span>}
                                </FormGroup>

                                <FormGroup>
                                    <Label for="coupon_value">Coupon Value:</Label>
                                    <input
                                        className="form-control"
                                        type="number"
                                        id="coupon_value"
                                        {...register('coupon_value')}
                                    />
                                    {errors.coupon_value && <span style={{ color: 'red' }}>{errors.coupon_value.message}</span>}
                                </FormGroup>

                                <FormGroup>
                                    <Label for="usage_limit">Usage Limit:</Label>
                                    <input
                                        className="form-control"
                                        type="number"
                                        id="usage_limit"
                                        {...register('usage_limit')}
                                    />
                                    {errors.usage_limit && <span style={{ color: 'red' }}>{errors.usage_limit.message}</span>}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="start_date">Start Date:</Label>
                                    <Controller
                                        control={control}
                                        name="start_date"
                                        render={({ field }) => (
                                            <DatePicker
                                                className="form-control"
                                                selected={field.value}
                                                onChange={field.onChange}
                                                showTimeSelect
                                                dateFormat="Pp"
                                            />
                                        )}
                                    />
                                    {errors.start_date && <span style={{ color: 'red' }}>{errors.start_date.message}</span>}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="end_date">End Date:</Label>
                                    <Controller
                                        control={control}
                                        name="end_date"
                                        render={({ field }) => (
                                            <DatePicker
                                                className="form-control"
                                                selected={field.value}
                                                onChange={field.onChange}
                                                showTimeSelect
                                                dateFormat="Pp"
                                            />
                                        )}
                                    />
                                    {errors.end_date && <span style={{ color: 'red' }}>{errors.end_date.message}</span>}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="exclude_category">Exclude Category:</Label>
                                    <CAsyncSelect
                                        name="exclude_category"
                                        control={control}
                                        isMulti={true}
                                        fetchOptions={async (
                                            value
                                        ) => {
                                            return getAllCategory(getCommonParams(value));
                                        }}
                                        modifyOptions={(categories) => {
                                            return categories.map((category) => ({
                                                label: category.name,
                                                value: category.id,
                                            }));
                                        }}
                                    />
                                </FormGroup>
                            </ModalBody>
                            <ModalFooter>
                                <Button type='submit' color="primary">
                                    Create Coupon
                                </Button>
                                <Button color="secondary" onClick={toggleModal}>
                                    Cancel
                                </Button>
                            </ModalFooter>
                        </Form>

                    </>
                }
            />
        </div>
    );
};

export default AddCoupon;

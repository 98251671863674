import { useNavigate, useParams } from "react-router";
import CCardHeader from "../../CommonElements/CardHeader/CardHeader";
import { Button, Card, CardBody, Container } from "reactstrap";
import remarkGfm from "remark-gfm";
import { Breadcrumbs } from "../../AbstractElements";
import { Fragment, useEffect } from "react";
import Markdown from "react-markdown";
import blogStore from "../../store/blogStore";
import { ArrowLeft } from "react-feather";
import Loader from "../../CommonElements/Loader/Loader";

const BlogDetailsPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { blogDetail, loading, getBlogDetails } = blogStore()
  useEffect(() => {
    getBlogDetails(id);
  }, [id])
  return (
    <Fragment>
      <Breadcrumbs parent="Home" title="Blog" mainTitle="Blog" />
      <Container fluid={true}>
        <Card>
          <CCardHeader title="Blog Details" leftContent={
            <Button
              className="me-3"
              color="primary"
              style={{
                padding: "0px",
                width: "40px",
                height: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => {
                navigate("/app/blogs")
              }}
            >
              <ArrowLeft />
            </Button>
          } />
          <CardBody>
            {
              loading ?
                <Loader />
                :
                <Markdown
                  children={blogDetail?.description}
                  remarkPlugins={[remarkGfm]}
                />
            }
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
};

export default BlogDetailsPage;

// ApiService.js
import axios from 'axios';


class ApiService {
  constructor() {
    if (!ApiService.instance) {
      this.api = axios.create({
        baseURL: process.env.REACT_APP_PUBLIC_URL, // Replace with your API base URL
        timeout: 10000,
        headers: {
          'Content-Type': 'application/json',
        },
      });

      this.api.interceptors.request.use(
        (config) => {
          const token = localStorage.getItem('access_token');
          if (token) {
            config.headers.Authorization = `Bearer ${token}`;
          }

          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );

      this.api.interceptors.response.use(
        (response) => response,
        (error) => {
          if (error.response && error.response.status === 401) {
            console.error('Unauthorized, logging out...');
            this.handleUnauthorized();
          }
          return Promise.reject(error);
        }
      );

      ApiService.instance = this;
    }

    return ApiService.instance;
  }
  // Logout logic for 401 error
  async handleUnauthorized() {
    try {
      localStorage.removeItem("access_token");
      // Programmatic navigation to login page
      window.location.href = '/login'; 
    } catch (error) {
      console.error("Error during logout process:", error);
    }
  }
  getInstance() {
    return this.api;
  }


}

const instance = new ApiService().getInstance();
Object.freeze(instance);

export default instance;

import React, { Fragment } from "react";
import { Container, Row } from "reactstrap";
import { Breadcrumbs } from "../../AbstractElements";
import GreetingCard from "../../Components/Dashboard/Default/GreetingCard";
import WidgetsWrapper from "../../Components/Dashboard/Default/WidgetsWraper";

const Dashboard = () => {
  return (
    <Fragment>
      <Breadcrumbs mainTitle="DashBoard" parent="DashBoard" title="DashBoard" />
      <Container fluid={true}>
        <Row className="widget-grid">
          <GreetingCard />
          <WidgetsWrapper />
        </Row>
      </Container>
    </Fragment>
  );
};

export default Dashboard;

import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const ImageModal = ({ isOpen, toggle, imageUrl }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Shipping Details</ModalHeader>
      <ModalBody>
        <img src={imageUrl} alt="Shipping Details" style={{ width: '100%' }} />
      </ModalBody>
    </Modal>
  );
};

export default ImageModal;

import { useRef, useState } from "react";
import { Edit, Plus } from "react-feather";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
} from "reactstrap";
import RModal from "../../CommonElements/RModal/RModal";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import SimpleMdeReact from "react-simplemde-editor";
import CCardHeader from "../../CommonElements/CardHeader/CardHeader";
import blogStore from "../../store/blogStore";

const blogSchema = z.object({
  title: z.string().min(1, "Title is required"),
});

const CreateBlog = () => {
  const { creating, createBlog } = blogStore();
  const descriptionRef = useRef("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({
    mode: "all",
    resolver: zodResolver(blogSchema),
    defaultValues: {
      title: "",
    },
  });

  const onSubmit = (data) => {
    if (descriptionRef.current != "") {
      data.description = descriptionRef.current;
      createBlog(data);
      reset();
    }
  };
  return (
    <Card>
      <CCardHeader title="Create Blog" />
      <CardBody>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label for="title">Title:</Label>
            <input
              className="form-control"
              type="text"
              {...register("title")}
            />
            {errors.title && (
              <span style={{ color: "red" }}>{errors.title.message}</span>
            )}
          </FormGroup>
          <FormGroup>
            <Label>Content :</Label>
            <SimpleMdeReact
              // value={descriptionRef.current}
              onChange={(value) => {
                descriptionRef.current = value;
              }}
              options={{
                autofocus: true,
                spellChecker: false,
              }}
            />
            {descriptionRef.current == "" && (
              <span style={{ color: "red" }}>Content is Required.</span>
            )}
          </FormGroup>
          <div>
            <Button type="submit" color="primary me-3">
              {
                creating ?
                  "Creating..."
                  :
                  "Create Blog"
              }
            </Button>
            <Button color="secondary">
              Cancel
            </Button>
          </div>
        </Form>
      </CardBody>
    </Card >
  );
};

export default CreateBlog;

import React, { useState } from "react";
import { Edit } from "react-feather";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import RModal from "../../../CommonElements/RModal/RModal";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import CSelect from "../../../CommonElements/CommonSelect/Select";
import siteFeatureStore from "../../../store/siteFeatureStore";
import { siteFeatureIcons } from "../../../utils/iconOptions";

const siteFeaturesSchema = z.object({
  title: z.string().min(1, "Name is required"),
  subtitle: z.string().min(1, "Name is required"),
  icon: z.object({
    value: z.string().min(1, { message: "Please select an Icon." }),
    label: z.string()
  }).nullable().refine(data => data && data.value && data.label, { message: "Icon is required" }),
});

const UpdateSiteFeature = ({ feature }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const store = siteFeatureStore();
  const icon = feature?.icon
    ? { value: feature?.icon, label: feature?.icon }
    : null;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({
    mode: "all",
    resolver: zodResolver(siteFeaturesSchema),
    values: {
      title: feature?.title,
      subtitle: feature?.subtitle,
      icon: icon,
    },
  });
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    reset();
  };
  const onSubmit = (data) => {
    const icon = data?.icon?.value;
    const newData = {
      ...data,
      icon: icon,
    };
    store.updateSiteFeature(feature?.id, newData);
    toggleModal();
  };
  return (
    <div>
      <Button
        className="p-1"
        style={{ width: "max-content" }}
        color="primary"
        size="sm"
        onClick={toggleModal}
      >
        <Edit />
      </Button>
      <RModal
        isOpen={isModalOpen}
        toggleModal={toggleModal}
        title="Update Site Feature"
        bodyContent={
          <>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <ModalBody>
                <FormGroup>
                  <Label for="title">Title:</Label>
                  <input
                    className="form-control"
                    type="text"
                    {...register("title")}
                  />
                  {errors.title && (
                    <span style={{ color: "red" }}>{errors.title.message}</span>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="icon">Sub-Title:</Label>
                  <input
                    className="form-control"
                    type="text"
                    {...register("subtitle")}
                  />
                  {errors.subtitle && (
                    <span style={{ color: "red" }}>{errors.subtitle.message}</span>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="icon">Icon:</Label>
                  <CSelect
                    control={control}
                    name="icon"
                    options={siteFeatureIcons}
                    formatOptionLabel={({ value, label }) => (
                      <div>
                          <i className={`icon-${value}`}></i> {label}
                      </div>
                  )}
                  />
                  {errors.icon && (
                    <span style={{ color: "red" }}>{errors.icon.message}</span>
                  )}
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button type="submit" color="primary">
                  Save Changes
                </Button>
                <Button color="secondary" onClick={toggleModal}>
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          </>
        }
      />
    </div>
  );
};

export default UpdateSiteFeature;

import React, { useState } from "react";
import { Edit, Plus } from "react-feather";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import RModal from "../../../CommonElements/RModal/RModal";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import CSelect from "../../../CommonElements/CommonSelect/Select";
import UploadImage from "../../../CommonElements/UploadImage/upload-image";
import shippingMethodStore from "../../../store/shippingMethodStore";

const shippingMethodSchema = z.object({
  name: z.string().min(1, "Name is required"),
  charge: z
    .string()
    .refine((val) => !isNaN(parseFloat(val)), {
      message: "charge must be a number",
    })
    .transform((val) => parseFloat(val))
    .refine((val) => val > 0, {
      message: "charge must be a positive number",
    }),
  status: z
    .object({
      label: z.string(),
      value: z.string().or(z.number()),
    })
    .nullable()
    .refine((val) => val !== null, {
      message: "Please select a status",
    }),
});

const AddShippingMethod = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const store = shippingMethodStore();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({
    mode: "all",
    resolver: zodResolver(shippingMethodSchema),
    defaultValues: {
      name: "",
      charge: 0,
      status: null,
    },
  });

  const onSubmit = (data) => {
    const status = data?.status?.value;
    const newData = {
      ...data,
      status: status,
    };
    store.createShippingMethod(newData);
    toggleModal();
  };
  return (
    <div>
      <Button
        className="p-1 d-flex align-items-center"
        style={{ width: "max-content" }}
        color="primary"
        size="sm"
        onClick={toggleModal}
      >
        <Plus /> Add Shipping Method
      </Button>
      <RModal
        isOpen={isModalOpen}
        toggleModal={toggleModal}
        title="Add Shipping Method"
        bodyContent={
          <>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <ModalBody>
                <FormGroup>
                  <Label for="name">Shipping Method Name:</Label>
                  <input
                    className="form-control"
                    type="text"
                    {...register("name")}
                  />
                  {errors.name && (
                    <span style={{ color: "red" }}>{errors.name.message}</span>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="icon">Charge:</Label>
                  <input
                    className="form-control"
                    type="text"
                    {...register("charge")}
                  />
                  {errors.charge && (
                    <span style={{ color: "red" }}>{errors.charge.message}</span>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="parent">Status</Label>
                  <CSelect
                    control={control}
                    name="status"
                    options={[
                      {
                        label: "True",
                        value: "true"
                      },
                      {
                        label: "False",
                        value: "false"
                      }
                    ]}
                  />
                  {errors.status && (
                    <span style={{ color: "red" }}>{errors.status.message}</span>
                  )}
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button type="submit" color="primary">
                  Add Shipping Method
                </Button>
                <Button color="secondary" onClick={toggleModal}>
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          </>
        }
      />
    </div>
  );
};

export default AddShippingMethod;

import { create } from "zustand";
import {
  createDeal,
  deleteDeal,
  getAllDeals,
  toggleDealStatus,
  updateDeal,
} from "../Services/dealService";

const dealsOfTheDay = create((set, get) => ({
  totalDeals: 0,
  deals: [],
  allOfferList: [],
  updateSettingOfferList: [],
  fetchDeals: async (params) => {
    try {
      const response = await getAllDeals(params);
      set((state) => ({
        deals: response.data?.results,
        totalDeals: response.data?.count,
      }));
    } catch (err) {
      console.error("Error fetching offer list:", err);
    }
  },

  createDeal: async (data) => {
    try {
      const response = await createDeal(data);
      await get().fetchDeals();
    } catch (error) {
      console.log("Error while creating offer:", error);
    }
  },

  updateDeal: async (id, data) => {
    try {
      const response = await updateDeal(id, data);
      await get().fetchDeals();
    } catch (err) {
      console.log("Error while updating offer:", err);
    }
  },
  toggleDealStatus: async (id) => {
    try {
      const response = await toggleDealStatus(id);
    } catch (err) {
      console.log("Error while updating offer:", err);
    }
  },

  deleteDeal: async (id) => {
    try {
      await deleteDeal(id);
      await get().fetchDeals();
    } catch (err) {
      console.error("Error while deleting offer:", err);
    }
  },
}));

export default dealsOfTheDay;

import { Container, Row, Col, Card, CardBody } from "reactstrap";
import HeaderCard from "../../../Components/Common/Component/HeaderCard";
import Table from "../../../CommonElements/Table/Table";
import { Fragment } from "react";
import TableHeader from "../../../CommonElements/TableHeader/TableHeader";
import CCardHeader from "../../../CommonElements/CardHeader/CardHeader";
import { addColumns } from "./add-columns";
import AddAdd from "./add-add-two";

const AddSectionTwo = ({addTwo}) => {

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CCardHeader title="Add Two" rightContent={<AddAdd/>} />
              <CardBody>
                <Table
                  data={addTwo}
                  columns={addColumns}
                  pagination={false}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AddSectionTwo;

import { create } from "zustand";
import {
  allBusinessType,
  createBusinessType,
  deleteBusinessType,
  editBusinessType,
} from "../Services/businessTypeService";

const BusinessTypeStore = create((set, get) => ({
  businessTypes: [],
  totalBusinessTypes: 0,

  fetchAllBusinessType: async (params) => {
    try {
      const response = await allBusinessType(params);
      set((state) => ({
        businessTypes: response.data?.results,
        totalBusinessTypes: response.data?.count,
      }));
    } catch (err) {
      console.error("Error fetching Business Type list:", err);
    }
  },

  createBusinessType: async (data) => {
    try {
      const response = await createBusinessType(data);
      await get().fetchAllBusinessType();
    } catch (error) {
      console.log("Error while creating Business Type:", error);
    }
  },
  updateBusinessType: async (id, data) => {
    try {
      const response = await editBusinessType(id, data);
      await get().fetchAllBusinessType();
    } catch (error) {
      console.log("Error while creating Business Type:", error);
    }
  },

  deleteBusinessType: async (id) => {
    try {
      await deleteBusinessType(id);
      await get().fetchAllBusinessType();
    } catch (err) {
      console.error("Error while deleting business type:", err);
    }
  },
}));

export default BusinessTypeStore;

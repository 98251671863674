import { useEffect, useState } from "react";
import { useStore } from "zustand";
import couponStore from "../../../store/couponStore";

const useCoupons = () => {
    const [searchKeyword, setSearchKeyword] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const { fetchAllCoupons, coupons, totalCoupons } = useStore(couponStore);

    useEffect(() => {
        (async () => {
            try {
                await fetchAllCoupons({ page: currentPage, count: pageSize, keyword: searchKeyword });
            } catch (error) {
                console.log(error);
            }
        })()
    }, [currentPage, pageSize, searchKeyword]);

    function handlePageChange(page) {
        setCurrentPage(page);
    }

    function handlePageSizeChange(pageSize) {
        setPageSize(pageSize);
    }

    function handleSearch(value) {
        setSearchKeyword(value);
    }

    return { data: coupons, totalRows: totalCoupons, handlePageChange, handlePageSizeChange, handleSearch }
};

export default useCoupons;
import { create } from "zustand";
import {
  getAllBusinessList,
  getProductByBusiness,
} from "../Services/businessService";

const businessStore = create((set) => ({
  totalBusiness: 0,
  businessList: [],
  businessProducts: [],
  selectedBusinessId: null,
  individualBusinessField: {},
  fetchAllBusiness: async (params) => {
    try {
      const response = await getAllBusinessList(params);
      set({
        totalBusiness: response.data?.count,
        businessList: response.data?.results,
      });
    } catch (err) {
      set({
        businessList: [],
      });
    }
  },

  fetchProductByBusinessId: async (id) => {
    try {
      const response = await getProductByBusiness(id);
      set({
        businessProducts: response.data,
        selectedBusinessId: id,
      });
    } catch (err) {
      console.error("Error fetching product:", err);
      throw err;
    }
  },
}));

export default businessStore;

import { create } from "zustand"
import { getStatistics, login } from "../Services/authService"
import tokenService from "../Services/tokenService";
import apiEndpoints from "../Services/apiEndpoints";
import { notificationType, showNotification } from "../utils/notification";
const authStore = create((set) =>
({
    signing: false,
    user_group: null,
    user: null,
    token: localStorage.getItem('token') || null,
    isLoading: false,
    errorMessage: null,
    loggedIn: null,
    loginForm: {
        email: "",
        password: ""
    },
    statistics: {
        total_products: 0,
        total_sellers: 0,
        total_users: 0,
        total_businesses: 0,
        total_orders: 0
    },
    updateLoginForm: (e) => {
        //take the name and value from where user hit and type
        const { name, value } = e.target;

        //set it
        set((state) => {
            return {
                loginForm: {
                    ...state.loginForm,
                    [name]: value
                }
            };
        })
    },

    login: async () => {
        set({ isLoading: true, error: null, signing: true });
        const { loginForm } = authStore.getState()
        try {
            const response = await login(loginForm);
            tokenService.setToken(apiEndpoints.accessTokenKey, response.data.access_token);
            tokenService.setToken(apiEndpoints.refreshToken, response.data.refresh_token)
            set({ user: response.user_object, user_group: response.user_group, token: response.token, isLoading: false, loggedIn: true, signing: false });
        } catch (err) {
            const errorMessage = err?.response?.data?.message || 'Login error';
            console.log(errorMessage, "from alsfdj")
            showNotification({
                type: notificationType.DANGER,
                message: errorMessage
            })
            set({ errorMessage: errorMessage, isLoading: false, loggedIn: false, signing: false })

        }
    },
    getStatistics: async () => {
        try {
            const response = await getStatistics();
            set({ statistics: response?.data });
        } catch (err) {
            console.log(err)
        }
    }
}))

export default authStore;
import apiEndpoints from "./apiEndpoints";
import apiServices from "./apiServices"

export const getAllAd1 = async() =>
{
    try{
        const response = await apiServices.get(apiEndpoints.getAddSection1)
        return response.data
    }catch(err)
    {
        console.error("Fetching error in ads1:",err);
        throw err
    }
}

export const updateAdSection1 = async(param,data) =>
{
    try{
        const response  =  await apiServices.post(apiEndpoints.updateAdSection1+param,data)
        return response.data
    }
    catch(err)
    {
        console.log("error in create ad2:",err)
        throw err
    }
}
export const getAllAd2 = async() =>
{
    try{
        const response = await apiServices.get(apiEndpoints.getAddSection2)

        return response.data
    }catch(err)
    {
        console.error("Fetching error in ads2:",err);
        throw err
    }
}

export const updateAdSection2 = async(param,data) =>
{
    try{
        const response  =  await apiServices.post(apiEndpoints.updateAdSection2+param,data)
        return response.data
    }
    catch(err)
    {
        console.log("error in create ad2:",err)
        throw err
    }
}
export const getAllAd3 = async() =>
{
    try{
        const response = await apiServices.get(apiEndpoints.getAddSection3)

        return response.data
    }catch(err)
    {
        console.error("Fetching error in ads3:",err);
        throw err
    }
}

export const updateAdSection3 = async(param,data) =>
{
    try{
        const response  =  await apiServices.post(apiEndpoints.updateAdSection3+param,data)
        return response.data
    }
    catch(err)
    {
        console.log("error in create ad3:",err)
        throw err
    }
}






export default {
    getAllAd1,
    updateAdSection1,
    getAllAd2,
    updateAdSection2,
    getAllAd3,
    updateAdSection3
}

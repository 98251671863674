import SearchInput from "../Search/SearchInput";

const TableHeader = ({ component,onSearch }) => {
  return (
    <div className="p-4">
      <SearchInput onSearch={onSearch}/>
      <div>
        {component && component} {/* Render the passed component */}
      </div>
    </div>
  );
};

export default TableHeader;

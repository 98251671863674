import { Button, Col, Media, Row } from "reactstrap";
import { Image } from "../../../AbstractElements";
import moment from "moment";
import ToggleBtn from "../../../CommonElements/toggleButton";
import {
  notificationType,
  showNotification,
} from "../../../utils/notification";
import { Edit, Trash } from "react-feather";
import { useState } from "react";
import categoryStore from "../../../store/categoryStore";
import DeleteSlide from "./delete-slide";

export const tableColumns = [
  {
    name: "Slide Image",
    selector: (row) => row,
    cell: (row) => (
      <Image
        attrImage={{
          src: row?.banner_image,
          style: {
            width: 60,
            fontSize: 14,
            padding: 4,
          },
          alt: row?.name,
        }}
      />
    ),
  },
  {
    name: "Slide URL",
    selector: (row) => row,
    center: true,
    sortable: true,
    cell: (row) => <a className="f-w-500" href={row?.banner_url} target="_blank">{row?.banner_url}</a>,
  },
  {
    name: "Actions",
    sortable: false,    
    cell: (row) => {
      return (
        <div className="d-flex justify-content-center" style={{ gap: "6px" }}>
          <DeleteSlide slide={row} />
        </div>
      );
    },
  },
];

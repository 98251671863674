import apiEndpoints from "./apiEndpoints";
import apiServices from "./apiServices"

export const getAllOrders = async (status, params) => {
    try {
        const queryString = new URLSearchParams(params).toString();
        const response = await apiServices.get(`${apiEndpoints.getOrderList}${status ? `/${status}`:''}?${queryString}`);
        return response.data;
    } catch (err) {
        console.error("Error while fetching orders from order:", err)
        throw err;
    }
}

export const getSellerOrdersBySellerId = async (sellerId, params) => {
    try {
        const queryString = new URLSearchParams(params).toString();
        const response = await apiServices.get(`${apiEndpoints.getShopOrderById}${sellerId ? `/${sellerId}`:''}?${queryString}`);
        return response.data;
    } catch (err) {
        console.error("error while get seller orderBy ID", err)
    }
}

export const getOrderDetails = async (id) => {
    try {
        const response = await apiServices.get(apiEndpoints.getSellerOrderInvoice + id);
        return response.data;
    } catch (err) {
        console.error("Error while fetching details from order:", err)
    }
}

export const updateOrderStatus = async (id, status) => {
    try {
        const response = await apiServices.post(apiEndpoints.editStatusOrder + id + "/" + status);
        return response.message;
    } catch (err) {
        console.log("error while changing status", err);
        throw err;
    }
}
import apiEndpoints from "./apiEndpoints";
import apiServices from "./apiServices"

export const getSiteInfo = async () => {
    try {
        const response = await apiServices.get(apiEndpoints.getSiteInfo)
        return response.data
    } catch (err) {
        console.error("Fetching error in site info:", err);
    }
}
export const updateSiteInfo = async (data) => {
    try {
        const response = await apiServices.post(apiEndpoints.updateSiteInfo, data)
        return response.data
    } catch (err) {
        console.error("Fetching error when updating site info:", err);
    }
}

import { create } from "zustand";
import { createSlider, deleteSlider, getSliders } from "../Services/sliderService";


const sliderStore = create((set, get) => ({
  sliders: [],
  totalSlider: 0,
  fetchAllSlider: async (params) => {
    try {
      const response = await getSliders(params);
      set((state) => ({
        sliders: response.data?.results,
        totalSlider: response.data?.count
      }));
    } catch (err) {
      console.error("Error fetching slider list:", err);
    }
  },
  createSlide: async (data) => {
    try {
      const response = await createSlider(data);
      await get().fetchAllSlider();
    } catch (error) {
      console.log("Error while creating slider:", error);
      throw error;
    }
  },
  deleteSlider: async (id) => {
    try {
      await deleteSlider(id);
      await get().fetchAllSlider();
    } catch (err) {
      console.error("Error while deleting slider:", err);
    }
  },
}));

export default sliderStore;

import apiEndpoints from "./apiEndpoints";
import apiServices from "./apiServices"

export const getSiteFeatures = async (params) => {
    try {
        const queryString = new URLSearchParams(params).toString();
        const response = await apiServices.get(`${apiEndpoints.getSiteFeatures}?${queryString}`);
        return response.data
    } catch (err) {
        console.error("Fetching error in site feature:", err);
    }
}
export const updateSiteFeature = async (id, data) => {
    try {
        const response = await apiServices.post(apiEndpoints.updateSiteFeature + id, data)
        return response.data
    } catch (err) {
        console.error("Fetching error when updating site feature:", err);
    }
}

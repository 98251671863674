import { create } from "zustand";
import { getShippingWithdrawList, getShippingWithdrawListByShop, getShippingWithdrawStatus } from "../Services/sellerShipppingWithdrawService";

const shippingWithdrawalStore = create((set, get) => ({
  updatePageSizeData: 1,
  updatePageCountData: 10,
  updateBusinessIdData: 0,
  totalRows: 0,
  errorMessage: "",
  allShippingWithdrawList: [],
  successMessage: "",

  // Fetch all shipping finance Filter list 
  fetchAllShippingWithdrawList: async () => {
    try {
      const { updatePageCountData, updatePageSizeData } = get();
      const response = await getShippingWithdrawList(updatePageCountData, updatePageSizeData);
      console.log(response);
      if (response.code === 200) {
        if (response.results && response.results.length > 0) { // Check if results array is not empty
          set({
            allShippingWithdrawList: response.results,
            totalRows: response.count,
            errorMessage: "",
          });
        } else {
          set({
            allShippingWithdrawList: [],
            totalRows: 0,
            successMessage: response.message,
          });
        }
      } else {
        set({
          errorMessage: response.message,
        });
        console.log("hello");
      }
    } catch (err) {
      console.error("Error while fetching shipping data", err);
      set({ errorMessage: "An error occurred while fetching filter data." });
      throw err;
    }
  },

  // Fetch all order List
  fetchAllShippingWithdrawListByShop: async () => {
    try {
      const { updatePageSizeData, updatePageCountData, updateBusinessIdData } = get();
      const response = await getShippingWithdrawListByShop(updateBusinessIdData, updatePageCountData, updatePageSizeData);
      if (response.code === 200) {
        set({
          allShippingWithdrawList: response.results,
          totalRows: response.count,
          errorMessage: "",
        });
      } else {
        set({
          errorMessage: response.message,
        });
      }
    } catch (err) {
      console.log("Error while fetching withdrawal list:", err);
      set({ errorMessage: "An error occurred while fetching filter data." });
      throw err;
    }
  },

  // Pagination for filter data
  updateFilterPaginationData: (count, pageSize) => {
    const { updateBusinessIdData } = get();
    if (updateBusinessIdData === null) {
      set({
        updatePageSizeData: count,
        updatePageCountData: pageSize,
      });
      get().fetchAllShippingWithdrawList();
    } else {
      set({
        updatePageSizeData: count,
        updatePageCountData: pageSize,
      });
      get().fetchAllShippingWithdrawListByShop();
    }
  },

  updateBusinessId: (businessId) => {
    if (businessId === null) {
      set({ updateBusinessIdData: businessId });
      get().fetchAllShippingWithdrawList();
    } else {
      set({ updateBusinessIdData: businessId });
      get().fetchAllShippingWithdrawListByShop();
    }
  },

  // Update status 
  updateRequestStatus: async (id, newStatus) => {
    try {
      const response = await getShippingWithdrawStatus(id, newStatus);
      if (response.code === 200) {
        set({
          successMessage: response.message,
        });
        get().fetchAllShippingWithdrawList(); // Re-fetch data after successful status change
      } else {
        set({
          errorMessage: response.message,
        });
      }
    } catch (err) {
      set({
        errorMessage: "An error occurred while changing status. Please try again later",
      });
      console.error("status error", err);
    }
  },
}));

export default shippingWithdrawalStore;

import apiEndpoints from "./apiEndpoints";
import apiServices from "./apiServices"

export const getPgHistory = async (params) => {
    try {

        const queryString = new URLSearchParams(params).toString();
        const response = await apiServices.get(`${apiEndpoints.fetchPgHistory}?${queryString}`);
        return response.data;
    } catch (err) {
        console.error("error while get payment gateway History list", err)
    }
}
export const getPgLogs = async (params) => {
    try {
        const queryString = new URLSearchParams(params).toString();
        const response = await apiServices.get(`${apiEndpoints.fetchPgLogs}?${queryString}`);
        return response.data;
    } catch (err) {
        console.error("error while get gateway log list", err)
    }
}

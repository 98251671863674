import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { ExternalLink } from 'react-feather';
import { Link } from 'react-router-dom';
import { Image } from '../../../AbstractElements';
import { OrderHistoryDataTable } from './OrderColumn';
import orderStore from '../../../store/orderStore';
import financeStore from '../../../store/financeStore';

// Function to get style based on status
const getStatusStyle = (status) => {
  switch (status) {
    case 'Pending':
      return { color: 'orange', fontWeight: 'bold' };
    case 'processing':
      return { color: 'blue', fontWeight: 'bold' };
    case 'On Hold':
      return { color: 'grey', fontWeight: 'bold' };
    case 'shipped':
      return { color: 'purple', fontWeight: 'bold' };
    case 'In Hub':
      return { color: 'navy', fontWeight: 'bold' };
    case 'Delivered':
      return { color: 'green', fontWeight: 'bold' };
    case 'Cancelled':
      return { color: 'red', fontWeight: 'bold' };
    case 'Refunded':
      return { color: '#26A5A9', fontWeight: 'bold' };
    case 'Returned':
      return { color: 'pink', fontWeight: 'bold' };
    default:
      return { fontWeight: 'bold' };
  }
};

// Function to get background style based on payment status
const getPaymentStatusStyle = (status) => {
  switch (status) {
    case 'Fully Paid':
      return { backgroundColor: 'green', color: 'white', padding: '5px', borderRadius: '4px' };
    case 'Partially Paid':
      return { backgroundColor: '#7DDA58', color: 'white', padding: '5px', borderRadius: '4px' };
    case 'DUE':
      return { backgroundColor: 'orange', color: 'white', padding: '5px', borderRadius: '4px' };
    case 'Cancelled':
      return { backgroundColor: '#A05455', color: 'white', padding: '5px', borderRadius: '4px' };
    case 'Failed':
      return { backgroundColor: '#D20103', color: 'white', padding: '5px', borderRadius: '4px' };

    default:
      return {};
  }
};

const OrderHistoryTable = () => {
  const { allFinanceList, updatePaginationData, totalRows } = financeStore(); // Assume `totalRows` is available from the store

  // State to keep track of current page
  const [currentPage, setCurrentPage] = useState(1);

  // State to keep track of number of rows per page
  const [dataCount, setDataCount] = useState(10);

  // Effect to fetch data when currentPage or dataCount changes
  useEffect(() => {
    updatePaginationData(currentPage, dataCount);

  }, [updatePaginationData, currentPage, dataCount]);

  // Handler for page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Handler for rows per page change
  const handleCountChange = (newPerPage, page) => {
    setDataCount(newPerPage); // Update data count
    setCurrentPage(page); // Update current page
    updatePaginationData(page, newPerPage); // Fetch new data based on updated page and count
  };

  const updatedData = {
    productRow: allFinanceList ? allFinanceList.map((item) => ({
      orderId: (
        <div className='product-name'>
          {item.order_id}
        </div>
      ),
      product: item?.product?.product_name,
      receivable: "৳" + item.seller_receivable,
      details: (
        <div>
          <p><strong>Quantity :</strong> {item.quantity}</p>
          <p><strong>Unit Price :</strong> {item.seller_receivable}</p>
          <p><strong>Total :</strong> {item.total_price}</p>
        </div>
      ),
      commision: (
        <div>
          <p><strong>Percentage :</strong>{item.commission_ratio}</p>
          <p><strong>Amount :</strong>{item.commission_amount}</p>
        </div>
      ),
      receivable: item.seller_receivable
    })) : [],
    productColumns: [
      {
        name: 'Order ID',
        selector: (row) => row.orderId,
        sortable: true,
        center: true,
        minWidth: '150px',
        maxWidth: '200px',
      },
      {
        name: 'Product',
        selector: (row) => row.product,
        sortable: true,
        center: true,
        minWidth: '100px',
        maxWidth: '250px',
      },
      {
        name: 'Details',
        selector: (row) => row.details,
        sortable: true,
        center: true,
        minWidth: '120px',
        maxWidth: '200px',
      },
      {
        name: 'Commision',
        selector: (row) => row.commision,
        sortable: true,
        center: true,
        minWidth: '120px',
        maxWidth: '180px',
      },
      {
        name: 'Receivable',
        selector: (row) => row.receivable,
        sortable: true,
        center: true,
      },
    ],
  }

  return (
    <div className='table-responsive table-bordernone orderHistoryTable product-table' >
      <DataTable
        pagination
        paginationServer
        paginationTotalRows={totalRows} // Ensure total number of rows is provided
        columns={updatedData.productColumns}
        data={updatedData.productRow}
        highlightOnHover={true}
        striped={true}
        responsive={true}
        onChangePage={handlePageChange} // Attach page change handler
        onChangeRowsPerPage={handleCountChange} // Attach rows per page change handler
      />
    </div >
  );
};

export default OrderHistoryTable;

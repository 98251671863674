import { Button, Col, Media, Row } from "reactstrap";
import { Image } from "../../../AbstractElements";
import moment from "moment";
import ToggleBtn from "../../../CommonElements/toggleButton";
import {
  notificationType,
  showNotification,
} from "../../../utils/notification";
import { Edit, Trash } from "react-feather";
import { useState } from "react";
import productStore from "../../../store/productStore";
import orderStore from "../../../store/orderStore";

export const tableColumns = [
  {
    name: "Order ID",
    selector: (row) => row,
    cell: (row) => (
      <span className="f-w-500">{row.order_id}</span>
    ),
  },
  {
    name: "Transaction ID",
    selector: (row) => row,
    center: true,
    sortable: true,
    cell: (row) => <span className="f-w-500">{row?.transaction_id}</span>,
  },
  {
    name: "Date Time",
    selector: (row) => row,
    sortable: true,
    center: true,
    cell: (row) => {
      const orderDate = moment(row.created_on).format('MMMM Do YYYY, h:mm A');
      return (
        <span className="badge badge-light-primary">{orderDate}</span>
      )
    },
  },
  {
    name: "GW Status",
    selector: (row) => row,
    sortable: true,
    center: true,
    cell: (row) => (
      <span className="badge badge-light-primary">{row.gw_status}</span>
    ),
  },
  {
    name: "Payment URL",
    selector: (row) => row,
    sortable: true,
    center: true,
    cell: (row) => (
      <a style={{color:"#0072b1"}} href={row.payment_url} target="_blank">Payment URL</a>
    ),
  },
  {
    name: "Status",
    selector: (row) => row,
    cell: (row) => {
      return (
        <div className="badge badge-light-primary">
          {row.status}
        </div>
      );
    },
  }
];

import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FileText, LogIn, Mail, User } from "react-feather";
import man from "../../../assets/images/dashboard/profile.png";
import tokenService from "../../../Services/tokenService";
import { useStore } from "zustand";
import { LI, UL, Image, P } from "../../../AbstractElements";
import CustomizerContext from "../../../_helper/Customizer";
import { Account, Admin, Inbox, LogOut, Taskboard } from "../../../Constant";
import userStore from "../../../store/userStore";
import useUser from "../../../pages/profile-page/hooks/useUser";
// import authStore from "../../../store/authStore";


const UserHeader = () => {
  const { user, loading, updateUserInfo } = useUser();
  // const store = authStore()
  // console.log(store.user)
  const history = useNavigate();
  const [profile, setProfile] = useState("");
  const [name, setName] = useState("Emay Walter");
  const { layoutURL } = useContext(CustomizerContext);
  const authenticated = JSON.parse(localStorage.getItem("authenticated"));
  const auth0_profile = JSON.parse(localStorage.getItem("auth0_profile"));

  const Logout = () => {
    localStorage.removeItem("profileURL");
    // localStorage.removeItem("token");
    // localStorage.removeItem("auth0_profile");
    // localStorage.removeItem("Name");
    // localStorage.setItem("authenticated", false);
    tokenService.removeToken()
    history(`/login`);
  };

  const UserMenuRedirect = (redirect) => {
    history(redirect);
  };

  return (
    <li className="profile-nav onhover-dropdown pe-0 py-0">
      <div className="media profile-media">
        <Image
          attrImage={{
            className: "b-r-10 m-0",
            src: `${authenticated ? auth0_profile.picture : profile}`,
            alt: "",
          }}
        />
        <div className="media-body">
          <span>{user?.first_name}</span>
          <P attrPara={{ className: "mb-0 font-roboto" }}>
           {user?.last_name}  <i className="middle fa fa-angle-down"></i>
          </P>
        </div>
      </div>
      <UL attrUL={{ className: "simple-list profile-dropdown onhover-show-div" }}>
        <LI
          attrLI={{
            onClick: () => UserMenuRedirect(`${process.env.PUBLIC_URL}/app/users/profile`),
          }}>
          <User />
          <span>{Account} </span>
        </LI>
        <LI attrLI={{ onClick: Logout }}>
          <LogIn />
          <span>{LogOut}</span>
        </LI>
      </UL>
    </li>
  );
};

export default UserHeader;

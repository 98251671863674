import { Button, Col, Media, Row } from "reactstrap";
import { Image } from "../../../AbstractElements";
import moment from "moment";
import ToggleBtn from "../../../CommonElements/toggleButton";
import {
  notificationType,
  showNotification,
} from "../../../utils/notification";
import { Edit, Trash } from "react-feather";
import { useState } from "react";
import UpdateSiteFeature from "./update-site-feature";

export const tableColumns = [
  {
    name: "Title",
    selector: (row) => row.title,
    center: true,
    sortable: true,
    cell: (row) => <span className="f-w-500">{row?.title}</span>,
  },
  {
    name: "Icon",
    selector: (row) => row,
    center: true,
    cell: (row) => (
      <span className="f-w-500">
        <i className={`icon-${row.icon}`}></i>
      </span>
    ),
  },
  {
    name: "Sub-Title",
    selector: (row) => row,
    center: true,
    cell: (row) => (
      <span className="f-w-400">
        {row.subtitle}</span>
    ),
  },
  {
    name: "Actions",
    sortable: true,
    center: true,
    cell: (row) => {
      return (
        <div className="d-flex" style={{ gap: "6px" }}>
          <UpdateSiteFeature feature={row} />
        </div>
      );
    },
  },
];

import React, { Fragment } from 'react';
import { CardHeader } from 'reactstrap';
import { H5 } from '../../AbstractElements';

const CCardHeader = ({ title, leftContent, rightContent, mainClasses }) => {
  return (
    <Fragment>
      <CardHeader className={`d-flex justify-content-between align-items-center ${mainClasses ? mainClasses : ''}`}>
        <div className='d-flex align-items-center'>
          <div className="header-left-content">
            {leftContent}
          </div>
          <H5>{title}</H5>
        </div>
        <div className="header-right-content">
          {rightContent}
        </div>
      </CardHeader>
    </Fragment>
  );
};

export default CCardHeader;

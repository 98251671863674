import React, { useState } from "react";
import { Edit, Plus } from "react-feather";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import RModal from "../../../CommonElements/RModal/RModal";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import CSelect from "../../../CommonElements/CommonSelect/Select";

// Zod schema for validation
import UploadImage from "../../../CommonElements/UploadImage/upload-image";
import paymentMethodStore from "../../../store/paymentMethodStore";

const paymentMethodSchema = z.object({
  name: z.string().min(1, "Name is required"),
  charge: z
    .string()
    .refine((val) => !isNaN(parseFloat(val)), {
      message: "charge must be a number",
    })
    .transform((val) => parseFloat(val))
    .refine((val) => val > 0, {
      message: "charge must be a positive number",
    }),
  status: z
    .object({
      label: z.string(),
      value: z.string().or(z.number()),
    })
    .nullable()
    .refine((val) => val !== null, {
      message: "Please select a status",
    }),
  test_mode: z
    .object({
      label: z.string(),
      value: z.string().or(z.number()),
    })
    .nullable()
    .refine((val) => val !== null, {
      message: "Please select a mode",
    }),
  logo: z.string().min(1, "Image is required"),
});

const AddPaymentMethod = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const store = paymentMethodStore();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({
    mode: "all",
    resolver: zodResolver(paymentMethodSchema),
    defaultValues: {
      name: "",
      charge: 0,
      status: null,
      test_mode: null,
      logo: "",
    },
  });

  const onSubmit = (data) => {
    const status = data?.status?.value;
    const test_mode = data?.test_mode?.value;
    const newData = {
      ...data,
      status: status,
      test_mode: test_mode
    };
    store.createPaymentMethod(newData);
    toggleModal();
  };
  return (
    <div>
      <Button
        className="p-1 d-flex align-items-center"
        style={{ width: "max-content" }}
        color="primary"
        size="sm"
        onClick={toggleModal}
      >
        <Plus /> Add Payment Method
      </Button>
      <RModal
        isOpen={isModalOpen}
        toggleModal={toggleModal}
        title="Add Payment Method"
        bodyContent={
          <>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <ModalBody>
                <FormGroup>
                  <Label for="name">Payment Method Name:</Label>
                  <input
                    className="form-control"
                    type="text"
                    {...register("name")}
                  />
                  {errors.name && (
                    <span style={{ color: "red" }}>{errors.name.message}</span>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="icon">Charge:</Label>
                  <input
                    className="form-control"
                    type="text"
                    {...register("charge")}
                  />
                  {errors.charge && (
                    <span style={{ color: "red" }}>{errors.charge.message}</span>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="parent">Status</Label>
                  <CSelect
                    control={control}
                    name="status"
                    options={[
                      {
                        label: "True",
                        value: "true"
                      },
                      {
                        label: "False",
                        value: "false"
                      }
                    ]}
                  />
                  {errors.status && (
                    <span style={{ color: "red" }}>{errors.status.message}</span>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="parent">Test Mode</Label>
                  <CSelect
                    control={control}
                    name="test_mode"
                    options={[
                      {
                        label: "True",
                        value: "true"
                      },
                      {
                        label: "False",
                        value: "false"
                      }
                    ]}
                  />
                  {errors.test_mode && (
                    <span style={{ color: "red" }}>{errors.test_mode.message}</span>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="logo">Payment Method Image:</Label>
                  <UploadImage control={control} name="logo" />
                  {errors.logo && (
                    <span style={{ color: "red" }}>{errors.logo.message}</span>
                  )}
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button type="submit" color="primary">
                  Add Payment Method
                </Button>
                <Button color="secondary" onClick={toggleModal}>
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          </>
        }
      />
    </div>
  );
};

export default AddPaymentMethod;
